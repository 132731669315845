class Style {
  constructor(styles) {
    this.styles = styles;
  }

  set(styles) {
    this.styles = styles;
  }

  css() {
    if (arguments.length < 1) {
      return undefined;
    } else if (arguments.length === 1) {
      let style = this.styles[arguments[0]];
      return this.styleExists(arguments[0]) ? style : undefined;
    } else if (arguments.length > 1) {
      let styleArray = [];

      for (let argument of arguments) {
        if (this.styleExists(argument)) {
          styleArray.push(this.styles[argument]);
        }
      }

      return this.styleArrayJoin(styleArray);
    }
  }

  compose() {
    let className = [];

    for (let argument of arguments) {
      className.push(argument);
    }

    return className.join(' ').trim();
  }

  size(prefix, size) {
    if (!size) return;

    let sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
    let sizesUntil = [];

    for (const item of sizes) {
      sizesUntil.push(item);
      if (item === size) break;
    }

    let classList = sizesUntil.map((postfix) => {
      return `${prefix}--${postfix}`;
    });

    return this.css.apply(this, classList);
  }

  styleExists(style) {
    return typeof this.styles[style] === 'undefined' ? false : true;
  }

  styleArrayJoin(styleArray) {
    if (styleArray.length <= 0) {
      return undefined;
    } else if (styleArray.length > 0) {
      return styleArray.join(' ').trim();
    }
  }
}

export default Style;
