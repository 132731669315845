import store from '../store';

export function fetchSelectParameter(parameter) {
  return fetch(
    `${window.App.config.apiURL}/${
      store.getState().domain.current
    }/parameter/select?parameter=${parameter}&token=${store.getState().user.token}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function fetchChangeParameter(parameter) {
  return fetch(
    `${window.App.config.apiURL}/${
      store.getState().domain.current
    }/parameter/change?parameter=${parameter}&token=${store.getState().user.token}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function fetchRemoveParameter(parameter) {
  return fetch(
    `${window.App.config.apiURL}/${
      store.getState().domain.current
    }/parameter/remove?parameter=${parameter}&token=${store.getState().user.token}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
