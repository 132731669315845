import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Style from '../../libs/style';

import styles from './ModalSave.module.scss';

export default function ModalSave(props) {
  let { size, url } = props;
  const [isCopied, setIsCopied] = useState();

  let { t } = useTranslation('translation');
  let style = new Style(styles);

  return (
    <div className={style.compose(style.css('modal-save', size ? `modal-save--${size}` : ''))}>
      <div className={style.css('modal-save__title')}>
        {t('translation.Common.Saving', 'Mentés')}
      </div>
      <div className={style.css('modal-save__subtitle')}>
        {t(
          'translation.Common.Copy the link below to save your configuration',
          'Másolja ki az alábbi linket a konfiguráció mentéséhez'
        )}
      </div>
      <div className={style.css('modal-save__form')}>
        <div className={style.css('modal-save__input')}>
          <input type='text' name='share-url' id='share-url' value={url} readOnly />
        </div>
      </div>
    </div>
  );
}
