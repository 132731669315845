import store from '../store';

export function fetchWhatIf(parameter) {
  return fetch(
    `${window.App.config.apiURL}/${
      store.getState().domain.current
    }/parameter/what-if?parameter=${parameter}&token=${store.getState().user.token}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
