import get from 'lodash/get';

export function getFirstImage(userData) {
  let gallery = get(userData, 'images.exterior.gallery[0].src');

  if (gallery) return gallery;

  let defaultImage = get(userData, 'images.exterior.default');
  if (defaultImage) return defaultImage;

  return null;
}
