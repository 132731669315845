import React from 'react';
import { useTranslation } from 'react-i18next';
import Nicon from '../Nicon/Nicon';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';

import styles from './ModellCard.module.scss';

export default function ModellCard(props) {
  let {
    id,
    language,
    currency,
    name,
    description,
    image,
    offersLink,
    starting_price,
    onClick: onClickCb,
  } = props;
  let { t } = useTranslation('translation');
  let style = new Style(styles);
  let currencyFormatter = formatter(language, currency);

  return (
    <div className={style.css('modell-card')} onClick={(e) => handleClick(e, id)}>
      <img src={image} alt='Nissan Modell' />
      <h2>
        {name} <Nicon size={19} icon={'chevright'} />
      </h2>
      <div
        className={style.css('modell-card__slogan')}
        dangerouslySetInnerHTML={{ __html: description }}></div>
      <div className={style.css('modell-card__price')}>
        <div className={style.css('modell-card__price-text')}>
          {t('translation.Common.Starting price', 'Kezdő ár')}
        </div>
        <div className={style.css('modell-card__price-value')}>
          {currencyFormatter.format(starting_price)}
        </div>
      </div>
      {offersLink && (
        <span
          className={style.css('modell-card__link')}
          onClick={() => handleOffersLinkClick(offersLink)}>
          {t('translation.Common.View offers', 'Ajánlatok megtekintése')}
        </span>
      )}
    </div>
  );

  function handleOffersLinkClick(offersLink) {
    let win = window.open(offersLink, '_blank');
    win.focus();
  }

  function handleClick(e, id) {
    if (e.target.className.includes('card__link')) return;
    onClickCb && onClickCb(id);
  }
}
