import React, { useLayoutEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import Style from '../../libs/style';
import { langISOToLang } from '../../libs/helper';
import { getFirstImage } from '../../libs/data';
import { useRouter } from '../../hooks/useRouter';
import { useMenu } from '../../hooks/useMenu';
import { useStore } from '../../hooks/useStore';
// import { useDevelopment } from '../../hooks/useDevelopment';
import Button from '../../components/Button/Button';
import MainMenu from '../../components/MainMenu/MainMenu';
import InfoBar from '../../components/InfoBar/InfoBar';
import SummaryList from '../../components/SummaryList/SummaryList';
import SummaryImage from '../../components/SummaryImage/SummaryImage';
import SpecificationList from '../../components/SpecificationList/SpecificationList';
import SpecificationUList from '../../components/SpecificationUList/SpecificationUList';
import ListTab from '../../components/ListTab/ListTab';
import Feature from '../../components/Feature/Feature';
import Modal from '../../components/Modal/Modal';
import ModalReset from '../../components/ModalReset/ModalReset';
import ModalSave from '../../components/ModalSave/ModalSave';
import { setWindowSize } from '../../actions/ui';
import { fetchUserData } from '../../actions/user';
import { syncUserToMenu, clearMenuSelection } from '../../actions/menu';
import { fetchSummaryData, setSummaryPrint } from '../../actions/summary';
import { fetchDomain } from '../../actions/domain';

import styles from './SummaryPage.module.scss';
import SummaryCarCard from '../../components/SummaryCarCard/SummaryCarCard';
let style = new Style(styles);

export default function SummaryPage() {
  let firstRun = useRef(true);
  let { menuIndex, setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();
  // let { setDefaults, setDevUser } = useDevelopment();
  let { userData } = useStore();
  let { t } = useTranslation('translation');
  let {
    saveModalVisible,
    shareModalVisible,
    handleSaveClick,
    handlePrintClick,
    handleShareClick,
    handleSaveModalClose,
    handleShareModalClose,
    handleStepClick,
    handleScrollChange,
    handleScrollVisibility,
    handleScrollIndexChange,
    handleResetClick,
  } = useMenu();

  let [selectedCategory, setSelectedCategory] = useState();
  let [resetModalVisible, setResetModalVisible] = useState(false);
  let [imageLoaded, setImageLoaded] = useState(false);
  let [sendModalVisible, setSendModalVisible] = useState(false);
  let [feature, setFeature] = useState(false);

  // If no modell selected, redirect to selection
  useLayoutEffect(() => {
    // setDefaults(1, 7, 1);
    // if (!store.user.token) setDevUser('97lik8c2');

    // Custom event
    window.parent.postMessage(
      JSON.stringify({
        type: 'step_data',
        step: 'step7-summary',
      }),
      '*'
    );

    // console.log(store.engine.selection, store.user.token);

    if (!store.engine.selection || !store.user.token) {
      setRoute('/engine');
    }
  }, []);

  // Initializing print if set in store
  useLayoutEffect(() => {
    if (!imageLoaded) return;
    if (!store.summary.print) return;

    dispatch(setSummaryPrint(false));
    window.print();
  }, [imageLoaded, store.summary.print]);

  // Syncing user object to menu selection
  useLayoutEffect(() => {
    if (firstRun.current) return;
    dispatch(syncUserToMenu(store.user.data));
  }, [store.user.dataUpdated]);

  // Fetching summary data
  useLayoutEffect(() => {
    if (store.user.token === null) return;
    dispatch(fetchSummaryData());
  }, [store.user.token, store.engine.selection]);

  // Fetching user data
  useLayoutEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (store.user.token === null) return;
    dispatch(fetchUserData(store.user.token));
  }, [store.user.token, store.accessory.dataUpdated]);

  let infobarData = {
    calculatorLink: null,
    title: get(store, 'summary.data.summary.car.name', ''),
    subtitle: `${get(store, 'summary.data.summary.category.name', '')} 
    ${get(store, 'summary.data.summary.engine.name', '')} 
    ${get(store, 'summary.data.summary.engine.power_transmission', '')} 
    ${get(store, 'summary.data.summary.engine.driven_wheels', '')}`,
    price: get(store, 'summary.data.summary.total', ''),
    onLinkClick: handleInfobarLinkClick,
  };

  let summaryListData = {
    onLinkClick: handleInfobarLinkClick,
    onResetClick: handleResetModalClick,
    calculatorLink: null,
    priceValue: get(store, 'summary.data.summary.total', ''),
    items: [
      {
        title: t('translation.Common.Equipment level', 'Felszereltségi szint'),
        route: '',
        name: get(store, 'summary.data.summary.category.name', ''),
        itemSuper: t('translation.Common.Starting list price', 'Kezdő listaár'),
        itemValue: get(store, 'summary.data.summary.level.starting_price', ''),
      },
      {
        title: t('translation.Common.Color', 'Szín'),
        route: '',
        name:
          Object.values(get(store, 'summary.data.summary.selections.color', ''))[0] &&
          Object.values(get(store, 'summary.data.summary.selections.color', ''))[0].name,
        itemValue: Object.values(get(store, 'summary.data.summary.selections.color', ''))[0]
          ? Object.values(get(store, 'summary.data.summary.selections.color', ''))[0].price
          : 0,
      },
      {
        title: t('translation.Common.Upholstery', 'Kárpit'),
        route: '',
        name:
          Object.values(get(store, 'summary.data.summary.selections.carpet', ''))[0] &&
          Object.values(get(store, 'summary.data.summary.selections.carpet', ''))[0].name,
        itemValue: Object.values(get(store, 'summary.data.summary.selections.carpet', ''))[0]
          ? Object.values(get(store, 'summary.data.summary.selections.carpet', ''))[0].price
          : 0,
      },
      {
        title: t('translation.Common.Rims', 'Keréktárcsák'),
        route: '',
        name:
          Object.values(get(store, 'summary.data.summary.selections.wheel', ''))[0] &&
          Object.values(get(store, 'summary.data.summary.selections.wheel', ''))[0].name,
        itemValue: Object.values(get(store, 'summary.data.summary.selections.wheel', ''))[0]
          ? Object.values(get(store, 'summary.data.summary.selections.wheel', ''))[0].price
          : 0,
      },
      {
        title: t('translation.Common.Packages and options', 'Csomagok és opciók'),
        route: '',
        items: Object.values(get(store, 'summary.data.summary.selections.option', '')),
        name: '',
        itemValue: '',
      },
      {
        title: t('translation.Common.Accessories', 'Tartozékok'),
        route: '',
        items: Object.values(get(store, 'summary.data.summary.selections.accessory', '')),
        name: '',
        itemValue: '',
      },
    ],
  };

  let specDataEngine = {
    size: 'xl',
    open: true,
    title: t('translation.Common.Specifications', 'Műszaki adatok'),
    lists: [
      {
        type: 'dlist',
        title: t('translation.Common.Engine category', 'Motorkategória'),
        items: [
          {
            name: t('translation.Common.Engine name', 'Motor neve'),
            value: get(store, 'summary.data.summary.engine.name', ''),
          },
          {
            name: t('translation.Common.Fuel', 'Üzemanyag'),
            value: get(store, 'summary.data.summary.engine.fuel', ''),
          },
          {
            name: t('translation.Common.Transmission', 'Erőátvitel'),
            value: get(store, 'summary.data.summary.engine.power_transmission', ''),
          },
          {
            name: t('translation.Common.Driven wheels', 'Meghajtott kerekek'),
            value: get(store, 'summary.data.summary.engine.driven_wheels', ''),
          },
          {
            name: t('translation.Common.HP', 'LE'),
            value: get(store, 'summary.data.summary.engine.power', ''),
          },
          {
            name: get(store, 'summary.data.summary.engine.fuel_consumption')
              ? t('translation.Common.Fuel consumption', 'Üzemanyag-fogyasztás')
              : !get(store, 'summary.data.summary.engine.fuel_consumption') &&
                get(store, 'summary.data.summary.engine.range')
              ? t('translation.Common.Range (WLTP) - Combined', 'Hatótávolság (WLTP), Kombinált')
              : '',
            value: get(store, 'summary.data.summary.engine.fuel_consumption')
              ? get(store, 'summary.data.summary.engine.fuel_consumption')
              : !get(store, 'summary.data.summary.engine.fuel_consumption') &&
                get(store, 'summary.data.summary.engine.range')
              ? get(store, 'summary.data.summary.engine.range')
              : '',
          },
          {
            name: t('translation.Common.CO2 emission', 'CO2 kibocsátás'),
            value: get(store, 'summary.data.summary.engine.co2', ''),
          },
        ],
      },
    ],
  };

  let specDataEquipment = {
    size: 'xl',
    open: true,
    title: t('translation.Common.Equipment information', 'Felszereltségi adatok'),
    ulist: get(store, 'summary.data.summary.category.details', []),
  };

  let tabData = [
    {
      label: t('translation.Common.Summary', 'Összegzés'),
      value: 'summary',
    },
    {
      label: t('translation.Common.Features', 'Jellemzők'),
      value: 'features',
    },
  ];

  let relatedCars = get(store, 'summary.relatedCars', []);
  let relatedCarsExact = relatedCars.filter((car) => car.score === '14');
  let relatedCarsMisc = relatedCars.filter((car) => car.score !== '14');
  let moreOffersLink =
    store.ui.language === 'hu'
      ? 'https://www.nissan.hu/jarmuvek/ajanlatok/azonnal-elviheto-autok.html'
      : store.ui.language === 'sk'
      ? 'https://www.nissan.sk/vozidla/ponuky/zoznam-skladovych-vozidiel.html'
      : store.ui.language === 'cs'
      ? 'https://www.nissan.cz/vozidla/nabidky/skladove-vozy.html'
      : '';

  return (
    <div className={style.css('summary-page', `summary-page--${store.ui.windowSize}`)}>
      <div className={style.css('menu')}>
        <MainMenu
          language={store.ui.language}
          index={menuIndex}
          scrollX={store.ui.menuScrollX}
          scrollIndex={store.ui.menuScrollIndex}
          scrollVisible={store.ui.menuScrollVisible}
          size={store.ui.windowSize}
          data={useMemo(() => store.menu, [store.menu])}
          onStepClick={handleStepClick}
          onPrintClick={store.user.token ? handlePrintClick : null}
          onSaveClick={store.user.token ? handleSaveClick : null}
          onShareClick={store.user.token ? handleShareClick : null}
          onScrollChange={handleScrollChange}
          onScrollIndexChange={handleScrollIndexChange}
          onScrollVisibility={handleScrollVisibility}
          onResetClick={handleResetClick}
        />
      </div>
      <div className={style.css('main')}>
        <div className={style.css('main__padding')}>
          <div className='row'>
            <div className={style.compose('col-12', style.css('main__top'))}>
              <InfoBar
                {...infobarData}
                size={store.ui.windowSize}
                language={store.ui.language}
                currency={store.ui.currency}
              />
            </div>
            <div className={style.compose('col-md-6', style.css('main__left'))}>
              <div className={style.css('summary-image__wrapper')}>
                <Feature fly={feature}>
                  <SummaryImage
                    onImageLoaded={handleSummaryImageLoaded}
                    onMaximizeClick={handleSummaryImageMaximize}
                    imgSrc={
                      get(userData, 'images.exterior.gallery[0].src') ||
                      get(userData, 'images.exterior.default') ||
                      ''
                    }
                  />
                </Feature>
              </div>
              <div className={style.css('actions')}>
                <Button color={'red'} onClick={() => setSendModalVisible(true)}>
                  {t('translation.Common.Id like a call back', 'Visszahívást kérek')}
                </Button>
                <a
                  href={get(store, 'summary.data.summary.test_drive_link', '')}
                  target='_blank'
                  rel='noreferrer noopener'>
                  <Button color={'red'}>
                    {t('translation.Common.Test drive', 'Tesztvezetés')}
                  </Button>
                </a>
                <a
                  href={get(store, 'summary.data.summary.price_list_pdf', '')}
                  target='_blank'
                  rel='noreferrer noopener'>
                  <Button color={'red'}>
                    {t('translation.Common.Prices and offers', 'Árak és ajánlatok')}
                  </Button>
                </a>
              </div>
              <div className={style.css('tabs')}>
                <div className={style.css('tabs__title')}>
                  {t(
                    'translation.Common.Learn more about the features of your car!',
                    'Tudjon meg többet autója tulajdonságairól!'
                  )}
                </div>
                <ListTab
                  data={tabData}
                  defaultValue={selectedCategory}
                  className={style.css('items__tab')}
                  onSelect={handleTabSelect}
                />
              </div>
              {(() => {
                if (store.ui.windowSize === 'xs' || store.ui.windowSize === 'sm') {
                  if (selectedCategory === 'summary') {
                    return (
                      <>
                        <div className={style.css('summary')}>
                          <div className={style.css('summary__title')}>
                            {t('translation.Common.Summary', 'Összegzés')}
                          </div>
                          <SummaryList
                            language={store.ui.language}
                            currency={store.ui.currency}
                            className={style.css('summary__list')}
                            {...summaryListData}
                            size={store.ui.windowSize}
                          />
                        </div>
                        <div className={style.css('specification', 'specification--print-only')}>
                          <div className={style.css('specification__title')}>
                            {t('translation.Common.Features', 'Jellemzők')}
                          </div>
                          <div className={style.css('specification__lists')}>
                            <div className={style.css('specification__wrapper')}>
                              <SpecificationList
                                {...specDataEngine}
                                size={store.ui.windowSize}
                                language={store.ui.language}
                                currency={store.ui.currency}
                              />
                            </div>
                            <div className={style.css('specification__wrapper')}>
                              <SpecificationUList
                                {...specDataEquipment}
                                size={store.ui.windowSize}
                                language={store.ui.language}
                                currency={store.ui.currency}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  } else if (selectedCategory === 'features') {
                    return (
                      <div className={style.css('specification')}>
                        <div className={style.css('specification__title')}>
                          {t('translation.Common.Features', 'Jellemzők')}
                        </div>
                        <div className={style.css('specification__lists')}>
                          <div className={style.css('specification__wrapper')}>
                            <SpecificationList
                              {...specDataEngine}
                              size={store.ui.windowSize}
                              language={store.ui.language}
                              currency={store.ui.currency}
                            />
                          </div>
                          <div className={style.css('specification__wrapper')}>
                            <SpecificationUList
                              {...specDataEquipment}
                              size={store.ui.windowSize}
                              language={store.ui.language}
                              currency={store.ui.currency}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                } else {
                  return (
                    <>
                      <div className={style.css('summary')}>
                        <div className={style.css('summary__title')}>
                          {t('translation.Common.Summary', 'Összegzés')}
                        </div>
                        <SummaryList
                          language={store.ui.language}
                          currency={store.ui.currency}
                          className={style.css('summary__list')}
                          {...summaryListData}
                          size={store.ui.windowSize}
                        />
                      </div>
                      <div className={style.css('specification')}>
                        <div className={style.css('specification__title')}>
                          {t('translation.Common.Features', 'Jellemzők')}
                        </div>
                        <div className={style.css('specification__lists')}>
                          <div className={style.css('specification__wrapper')}>
                            <SpecificationList
                              {...specDataEngine}
                              size={store.ui.windowSize}
                              language={store.ui.language}
                              currency={store.ui.currency}
                            />
                          </div>
                          <div className={style.css('specification__wrapper')}>
                            <SpecificationUList
                              {...specDataEquipment}
                              size={store.ui.windowSize}
                              language={store.ui.language}
                              currency={store.ui.currency}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })()}
            </div>
            <div className={style.compose('col-md-6', style.css('main__right'))}>
              <div className={style.css('finisher')}>
                <div className={style.css('finisher__wrapper')}>
                  {relatedCarsExact.length > 0 && (
                    <>
                      <div className={style.css('finisher__title')}>
                        {t(
                          'translation.Common.We found it in stock for you',
                          'Megtaláltuk Önnek készleten'
                        )}
                      </div>
                      <div className={style.css('finisher__matches')}>
                        {relatedCarsExact.map((car, carIndex) => (
                          <div className={style.css('finisher__match')}>
                            <SummaryCarCard
                              key={carIndex}
                              car={car}
                              language={store.ui.language}
                              currency={store.ui.currency}></SummaryCarCard>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {relatedCarsMisc.length > 0 && (
                    <>
                      <div className={style.css('finisher__title')}>
                        {t(
                          'translation.Common.Similar models from stock',
                          'Hasonló modellek készletről'
                        )}
                      </div>
                      <div className={style.css('finisher__matches')}>
                        {relatedCarsMisc.map((car, carIndex) => (
                          <div className={style.css('finisher__match')} key={carIndex}>
                            <SummaryCarCard
                              car={car}
                              language={store.ui.language}
                              currency={store.ui.currency}></SummaryCarCard>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <div className={style.css('finisher__actions')}>
                    <a href={moreOffersLink} target='_blank' rel='noreferrer noopener'>
                      <Button color={'red'}>
                        {t('translation.Common.More stock offers', 'További készletajánlatok')}
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {shareModalVisible && (
        <Modal
          className={style.css('share-modal')}
          size={store.ui.windowSize}
          visible={true}
          mode={'side'}
          onClose={handleShareModalClose}>
          <iframe
            className={style.css('share-modal__iframe')}
            src={`https://ni-content.hu/nissan_config/${langISOToLang(
              store.ui.language
            )}/share?model=${get(userData, 'modell.name')}&shareURL=${encodeURIComponent(
              `${get(userData, 'domain.share_url')}?token=${store.user.token}`
            )}&image=${encodeURIComponent(getFirstImage(store.user.data))}`}
            title='Nissan share'></iframe>
        </Modal>
      )}
      {saveModalVisible && (
        <Modal
          size={store.ui.windowSize}
          excolor={'#c3002f'}
          visible={true}
          onClose={handleSaveModalClose}>
          <ModalSave
            url={`${get(userData, 'domain.share_url')}?token=${get(userData, 'user.token')}`}
            size={store.ui.windowSize}
          />
        </Modal>
      )}
      {resetModalVisible && (
        <Modal
          size={store.ui.windowSize}
          excolor={'#c3002f'}
          visible={true}
          onClose={handleResetModalClose}>
          <ModalReset onButtonClick={handleResetButtonClick} size={store.ui.windowSize} />
        </Modal>
      )}
      {sendModalVisible && (
        <Modal
          className={style.css('send-modal')}
          size={store.ui.windowSize}
          visible={true}
          mode={'side'}
          onClose={handleSendModalClose}>
          <iframe
            className={style.css('send-modal__iframe')}
            src={`https://ni-content.hu/nissan_config/${langISOToLang(
              store.ui.language
            )}?model=${get(userData, 'modell.name')}&shareURL=${encodeURIComponent(
              `${get(userData, 'domain.share_url')}?token=${store.user.token}`
            )}&image=${encodeURIComponent(getFirstImage(store.user.data))}`}
            title='Nissan share'></iframe>
        </Modal>
      )}
    </div>
  );

  function handleSummaryImageLoaded() {
    setImageLoaded(true);
  }

  function handleSummaryImageMaximize() {
    setFeature(!feature);
  }

  function handleSendModalClose() {
    setSendModalVisible(false);
  }

  function handleResetModalClose() {
    setResetModalVisible(false);
  }

  function handleResetModalClick() {
    setResetModalVisible(true);
  }

  function handleResetButtonClick() {
    dispatch(clearMenuSelection());
    dispatch({ type: 'EXIT' });
    dispatch(fetchDomain());
    dispatch(setWindowSize(window.innerWidth, window.innerHeight));
    setRoute('/');
  }

  function handleInfobarLinkClick() {
    if (!get(store, 'summary.data.summary.calculator_link', '')) return;
    let win = window.open(get(store, 'summary.data.summary.calculator_link', ''), '_blank');
    win.focus();
  }

  function handleTabSelect(category) {
    if (selectedCategory === category) {
      setSelectedCategory();
    } else {
      setSelectedCategory(category);
    }
  }
}
