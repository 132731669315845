import { setUserToken } from './user';

export function fetchEngine(carId, categoryId) {
  return (dispatch, getState) => {
    fetch(
      `${window.App.config.apiURL}/${
        getState().domain.current
      }/motor?car=${carId}&category=${categoryId}`
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(setEngineData(data.data));
      });
  };
}

export function fetchEngineChange(engineId, done) {
  return (dispatch, getState) => {
    fetch(
      `${window.App.config.apiURL}/${
        getState().domain.current
      }/motor/change?motor=${engineId}&token=${getState().user.token}`
    )
      .then((response) => response.json())
      .then((data) => {
        // The user has been marked as a "snapshot"
        // He cant be edited further so we need to set the new token
        if (data.user.token !== getState().user.token) {
          dispatch(setUserToken(data.user.token));
        }

        done && done();
      });
  };
}

export function setEngineData(data) {
  return {
    type: 'SET_ENGINE_DATA',
    payload: data,
  };
}

export function setEngineSelection(id) {
  return {
    type: 'SET_ENGINE_SELECTION',
    payload: id,
  };
}
