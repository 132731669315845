import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getArrayItemById } from '../libs/helper';
import get from 'lodash/get';

function useStore() {
  let store = useSelector((store) => store);
  let [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData({
      domain: getArrayItemById(store.domain.data, store.domain.current),
      user: store.user,
      modell: getDataObject('user.data.car', 'modell'),
      equipment: getDataObject('user.data.category', 'equipment'),
      engine: getDataObject('user.data.level', 'engine'),
      color: getDataObject('user.data.parameters.color', 'color.data'),
      upholstery: getDataObject('user.data.parameters.carpet', 'upholstery.data'),
      wheel: getDataObject('user.data.parameters.wheel', 'wheel.data'),
      images: store.user.data.images,
    });
  }, [store.user.dataUpdated]);

  function getDataObject(userPath, storePath) {
    let userData = get(store, userPath, null);
    let apiData = get(store, storePath, null);

    if (!userData || !apiData) return;
    if (!Object.keys(apiData)?.length) return;

    let userDataKeys = [];

    if (userData.id) {
      userDataKeys.push(userData.id);
    } else {
      if (!Object.keys(userData)?.length) return;
      userDataKeys = Object.keys(userData).map((key) => parseInt(key));
    }

    let dataObjects = apiData.data?.filter?.((dataObject) => {
      return userDataKeys.includes(dataObject.id) ? true : false;
    });

    if (dataObjects?.length) {
      return dataObjects?.length === 1 ? dataObjects[0] : dataObjects;
    } else {
      return;
    }
  }

  return {
    userData,
  };
}

export { useStore };
