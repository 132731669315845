import React from 'react';
import Style from '../../libs/style';
import Nicon from '../Nicon/Nicon';

import styles from './Toast.module.scss';
let style = new Style(styles);

export default function Toast(props) {
  let { size, title, info, onClose } = props;

  return title || info ? (
    <div className={style.css('toast', size ? `toast--${size}` : '')}>
      <div className={style.css('toast__title')}>{title}</div>
      <div className={style.css('toast__info')}>{info}</div>
      <Nicon icon={'ex'} className={style.css('toast__close')} onClick={handleClose} />
    </div>
  ) : (
    ''
  );

  function handleClose() {
    onClose && onClose();
  }
}
