import { fetchWhatIf } from './whatif';
import { fetchSelectParameter, fetchChangeParameter, fetchRemoveParameter } from './select';
import { setUserToken } from './user';

export function fetchOptionData() {
  return (dispatch, getState) => {
    fetch(
      `${window.App.config.apiURL}/${getState().domain.current}/parameter?stage=option&token=${
        getState().user.token
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(setOptionData(data));
      });
  };
}

export function changeOptionWhatIf(parameter, index) {
  return (dispatch, getState) => {
    fetchWhatIf(parameter).then((data) => {
      dispatch(setOptionWhatIf(data, index));
    });
  };
}

export function selectOption(parameter) {
  return (dispatch, getState) => {
    fetchSelectParameter(parameter).then((data) => {
      // The user has been marked as a "snapshot"
      // He cant be edited further so we need to set the new token
      if (data.user.token !== getState().user.token) {
        dispatch(setUserToken(data.user.token));
      }
      dispatch(fetchOptionData());
    });
  };
}

export function changeOption(parameter) {
  return (dispatch, getState) => {
    fetchChangeParameter(parameter).then((data) => {
      // The user has been marked as a "snapshot"
      // He cant be edited further so we need to set the new token
      if (data.user.token !== getState().user.token) {
        dispatch(setUserToken(data.user.token));
      }
      dispatch(fetchOptionData());
    });
  };
}

export function removeOption(parameter) {
  return (dispatch, getState) => {
    fetchRemoveParameter(parameter).then((data) => {
      // The user has been marked as a "snapshot"
      // He cant be edited further so we need to set the new token
      if (data.user.token !== getState().user.token) {
        dispatch(setUserToken(data.user.token));
      }
      dispatch(fetchOptionData());
    });
  };
}

export function setOptionWhatIf(data, index) {
  return {
    type: 'SET_OPTION_WHATIF',
    payload: { data: data, index: index },
  };
}

export function setOptionData(data) {
  return {
    type: 'SET_OPTION_DATA',
    payload: data,
  };
}
