import React, { useState, useEffect } from 'react';
import Style from '../../libs/style';

import styles from './SummaryImage.module.scss';

export default function SummaryImage(props) {
  let { size, className, imgSrc, onMaximizeClick: onMaximizeClickCb, onImageLoaded } = props;
  let style = new Style(styles);

  let [maximized, setMaximized] = useState(false);

  useEffect(() => {
    if (!imgSrc) return;

    var image = new Image();
    image.addEventListener(
      'load',
      () => {
        onImageLoaded && onImageLoaded();
      },
      false
    );
    image.src = imgSrc;
  }, [imgSrc]);

  return (
    <div
      className={style.compose(
        style.css('summary-image', size ? `summary-image--${size}` : ''),
        className
      )}>
      <div className={style.css('summary-image__img')}>
        <img src={imgSrc} alt='Summary' />
      </div>
      <div
        className={style.css(
          'summary-image__maximize',
          maximized && 'summary-image__maximize--maximized'
        )}
        onClick={onMaximizeClick}></div>
    </div>
  );

  function onMaximizeClick() {
    setMaximized(!maximized);
    onMaximizeClickCb && onMaximizeClickCb();
  }
}
