import React, { useLayoutEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Style from '../../libs/style';
import { getArrayItemIndexById, langISOToLang } from '../../libs/helper';
import { getFirstImage } from '../../libs/data';
import { useRouter } from '../../hooks/useRouter';
import { useMenu } from '../../hooks/useMenu';
import { useStore } from '../../hooks/useStore';
import { useDevelopment } from '../../hooks/useDevelopment';
import Button from '../../components/Button/Button';
import MainMenu from '../../components/MainMenu/MainMenu';
import TitleSelection from '../../components/TitleSelection/TitleSelection';
import Carousel from '../../components/Carousel/Carousel';
import ListCard from '../../components/ListCard/ListCard';
import ItemListOptions from '../../components/ItemListOptions/ItemListOptions';
import InfoImage from '../../components/InfoImage/InfoImage';
import Feature from '../../components/Feature/Feature';
import Modal from '../../components/Modal/Modal';
import ModalSave from '../../components/ModalSave/ModalSave';
import EquipmentLister from '../../components/EquipmentLister/EquipmentLister';
import ListTab from '../../components/ListTab/ListTab';
import Dropdown from '../../components/Dropdown/Dropdown';
import { setGalleryMode, setGalleryIndex } from '../../actions/ui';
import {
  fetchOptionData,
  changeOptionWhatIf,
  selectOption,
  changeOption,
  removeOption,
} from '../../actions/option';
import { fetchUserData } from '../../actions/user';
import { syncUserToMenu } from '../../actions/menu';
import get from 'lodash/get';

import styles from './OptionsPage.module.scss';
let style = new Style(styles);

export default function OptionsPage() {
  let firstRun = useRef(true);
  let scrollRef = useRef();
  let { menuIndex, setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();
  let { setDefaults, setDevUser } = useDevelopment();
  let { userData } = useStore();
  let { t } = useTranslation('translation');
  let {
    saveModalVisible,
    shareModalVisible,
    handleSaveClick,
    handlePrintClick,
    handleShareClick,
    handleSaveModalClose,
    handleShareModalClose,
    handleStepClick,
    handleScrollChange,
    handleScrollVisibility,
    handleScrollIndexChange,
    handleResetClick,
  } = useMenu();

  let [modalVisible, setModalVisible] = useState(false);
  let [detailsSelected, setDetailsSelected] = useState(null);
  let [listData, setListData] = useState([]);
  let [featureInfoImageOpen, setFeatureInfoImageOpen] = useState(false);
  let [filteredListDataByTab, setFilteredListDataByTab] = useState([]);
  let [filteredListDataByDropdown, setFilteredListDataByDropdown] = useState([]);
  let [tabData, setTabData] = useState([]);
  let [dropdownData, setDropdownData] = useState([]);
  let [carouselMaximized, setCarouselMaximized] = useState(false);
  let [itemChanging, setItemChanging] = useState(null);
  let [selectedCategory, setSelectedCategory] = useState('package');
  let [selectedSubCategory, setSelectedSubCategory] = useState('all');

  let handleGalleryMaximizeClick = useCallback(() => {
    setCarouselMaximized(!carouselMaximized);
  });

  let handleGalleryModeClick = useCallback(() => {
    dispatch(setGalleryMode(store.ui.galleryMode === 'exterior' ? 'interior' : 'exterior'));
  });

  let handleGalleryIndexChange = useCallback((index) => {
    dispatch(setGalleryIndex(index));
  });

  let handleInfoImageMaximizeClick = useCallback(() => {
    setFeatureInfoImageOpen(!featureInfoImageOpen);
  });

  // If no modell selected, redirect to selection
  useLayoutEffect(() => {
    // setDefaults(1, 1, 1);
    // if (!store.user.token) setDevUser('9605p2yw');

    if (!store.engine.selection || !store.user.token) {
      setRoute('/engine');
    }
  }, []);

  // Syncing user object to menu selection
  useLayoutEffect(() => {
    if (firstRun.current) return;
    dispatch(syncUserToMenu(store.user.data));
  }, [store.user.dataUpdated]);

  // Fetching option data
  useLayoutEffect(() => {
    if (store.user.token === null) return;
    dispatch(fetchOptionData());
  }, [store.user.token, store.engine.selection]);

  // Fetching user data
  useLayoutEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (store.user.token === null) return;
    dispatch(fetchUserData(store.user.token));
  }, [store.user.token, store.option.dataUpdated]);

  // If Item is changing we need to fetch the change data
  useLayoutEffect(() => {
    if (itemChanging === null) return;

    // Were adding what-if data to option data
    let itemChangingIndex = getIndexById(listData, itemChanging);
    dispatch(changeOptionWhatIf(listData[itemChangingIndex].id, itemChangingIndex));
  }, [itemChanging]);

  // Processing api response into grouped list
  useLayoutEffect(() => {
    if (!get(store, 'option.data.data')) return;
    if (Object.keys(store.option.data).length <= 0) return;

    let newListData = store.option.data.data.map((item) => {
      return {
        id: item.id,
        group: item.group,
        subcategory: item.subcategory,
        elem: (
          <ListCard
            name={item.name}
            list={item.description}
            price={item.price}
            language={store.ui.language}
            currency={store.ui.currency}
          />
        ),
        change: item.additional.newGrade && {
          id: item.additional.newGrade.id,
          sum: item.additional.newGrade.starting_price,
          removeList: item.additional.newGrade.removeList,
          replaceList: item.additional.newGrade.replaceList,
        },
      };
    });

    setListData(newListData);
  }, [store.option.dataUpdated]);

  // Creating tab data by groups
  useLayoutEffect(() => {
    if (Object.keys(store.option.data).length <= 0) return;

    let translations = {
      package: t('translation.Common.Package options', 'Csomag opciók'),
      option: t('translation.Common.Individual options', 'Egyedi opciók'),
    };

    setTabData(translateDropdownData(translations, groupTabData(listData)));
  }, [listData]);

  // Creating dropdown data by subcategories
  useLayoutEffect(() => {
    if (Object.keys(store.option.data).length <= 0) return;
    let groupedData = [];

    groupedData = groupDropdownData(filteredListDataByTab);

    groupedData.unshift({
      label: t('translation.Common.All categories', 'Minden kategória'),
      value: 'all',
    });

    setDropdownData(groupedData);
  }, [filteredListDataByTab]);

  // Filtering listData by Tab
  useLayoutEffect(() => {
    if (Object.keys(store.option.data).length <= 0) return;
    let filteredData = [];

    filteredData = filterDataByGroup(listData, selectedCategory);

    setSelectedSubCategory('all');
    setFilteredListDataByTab(filteredData);
  }, [listData, selectedCategory]);

  // Filtering subcategories by dropdown
  useLayoutEffect(() => {
    if (Object.keys(store.option.data).length <= 0) return;

    setFilteredListDataByDropdown(
      filterDataBySubcategory(filteredListDataByTab, selectedSubCategory)
    );
  }, [filteredListDataByTab, selectedSubCategory]);

  // Reset scroll if changing subcategory
  useLayoutEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [selectedSubCategory]);

  let bannerData = {
    language: store.ui.language,
    currency: store.ui.currency,
    big: featureInfoImageOpen ? true : false,
    exteriorImage:
      get(userData, 'images.exterior.gallery[0].src') ||
      get(userData, 'images.exterior.default') ||
      '',
    interiorImage:
      get(userData, 'images.interior.gallery[0].src') ||
      get(userData, 'images.interior.default') ||
      '',
    modell: store.user.data.car && store.user.data.car.name,
    equipment: store.user.data.category && store.user.data.category.name,
    engine: store.user.data.level && store.user.data.level.motor,
    wheels: store.user.data.level && store.user.data.level.driven_wheels,
    transmission: store.user.data.level && store.user.data.level.power_transmission,
    price: store.user.data.price,
    handleMaximizeClick: handleInfoImageMaximizeClick,
  };

  return (
    <div className={style.css('wheels-page', `wheels-page--${store.ui.windowSize}`)}>
      <div className={style.css('menu')}>
        <MainMenu
          language={store.ui.language}
          index={menuIndex}
          scrollX={store.ui.menuScrollX}
          scrollIndex={store.ui.menuScrollIndex}
          scrollVisible={store.ui.menuScrollVisible}
          size={store.ui.windowSize}
          data={useMemo(() => store.menu, [store.menu])}
          onStepClick={handleStepClick}
          onPrintClick={store.user.token ? handlePrintClick : null}
          onSaveClick={store.user.token ? handleSaveClick : null}
          onShareClick={store.user.token ? handleShareClick : null}
          onScrollChange={handleScrollChange}
          onScrollIndexChange={handleScrollIndexChange}
          onScrollVisibility={handleScrollVisibility}
          onResetClick={handleResetClick}
        />
      </div>
      <div className={style.css('main')}>
        <div className={style.css('left', carouselMaximized ? 'left--minimized' : '')}>
          <div className={style.css('info-image___wrapper')}>
            {store.ui.windowSize === 'xs' && (
              <Feature fly={featureInfoImageOpen}>
                <InfoImage {...bannerData} />
              </Feature>
            )}
          </div>
          <div className={style.css('title-selection___wrapper')}>
            <TitleSelection
              title={
                store.ui.windowSize === 'xs'
                  ? ''
                  : t('translation.Common.Packages and options', 'Csomagok és opciók')
              }
            />
          </div>
          <div className={style.css('items')}>
            <div className={style.css('items__wrapper')}>
              <div className={style.css('items__controll')}>
                <ListTab
                  data={tabData}
                  defaultValue={selectedCategory}
                  className={style.css('items__tab')}
                  onSelect={handleTabSelect}
                />
                {dropdownData.length > 2 && (
                  <Dropdown
                    data={dropdownData}
                    onSelect={setSelectedSubCategory}
                    defaultValue={'all'}
                    selected={selectedSubCategory}
                    className={style.css('items__dropdown')}
                  />
                )}
              </div>
              <div className={style.css('items__scroll')} ref={scrollRef}>
                <ItemListOptions
                  language={store.ui.language}
                  currency={store.ui.currency}
                  size={store.ui.windowSize}
                  selected={getUserOptionsId(store.user.data)}
                  itemChanging={itemChanging}
                  listData={filteredListDataByDropdown}
                  itemsPerRow={{ xs: 1 }}
                  className={style.css('options-lists')}
                  onInfoClick={handleInfoClick}
                  handleSelect={handleItemSelect}
                  handleRemove={handleItemRemove}></ItemListOptions>
              </div>
            </div>
          </div>
          <div className={style.css('step-button')}>
            <Button text={'button'} color={'red'} icon={'chevron'} onClick={handleNextButtonClick}>
              <span style={{ fontWeight: '400' }}>
                {t('translation.Common.Next step', 'Következő lépés')}:
              </span>{' '}
              <strong>{t('translation.Common.Accessories', 'Tartozékok')}</strong>
            </Button>
          </div>
        </div>
        <div
          className={style.css(
            'right',
            carouselMaximized ? 'right--maximized' : '',
            store.ui.windowSize === 'xs' ? 'right--hidden' : ''
          )}>
          <div className={style.css('carousel-wrapper')}>
            <Carousel
              language={store.ui.language}
              currency={store.ui.currency}
              mode={store.ui.galleryMode}
              index={store.ui.galleryIndex}
              images={userData.images}
              banner={bannerData}
              onMaximizeClick={handleGalleryMaximizeClick}
              onModeClick={handleGalleryModeClick}
              onIndexChange={handleGalleryIndexChange}
            />
          </div>
        </div>
      </div>
      {shareModalVisible && (
        <Modal
          className={style.css('share-modal')}
          size={store.ui.windowSize}
          visible={true}
          mode={'side'}
          onClose={handleShareModalClose}>
          <iframe
            className={style.css('share-modal__iframe')}
            src={`https://ni-content.hu/nissan_config/${langISOToLang(
              store.ui.language
            )}/share?model=${get(userData, 'modell.name')}&shareURL=${encodeURIComponent(
              `${get(userData, 'domain.share_url')}?token=${store.user.token}`
            )}&image=${encodeURIComponent(getFirstImage(store.user.data))}`}
            title='Nissan share'></iframe>
        </Modal>
      )}
      {saveModalVisible && (
        <Modal
          size={store.ui.windowSize}
          excolor={'#c3002f'}
          visible={true}
          onClose={handleSaveModalClose}>
          <ModalSave
            url={`${get(userData, 'domain.share_url')}?token=${get(userData, 'user.token')}`}
            size={store.ui.windowSize}
          />
        </Modal>
      )}
      <Modal
        size={store.ui.windowSize}
        visible={modalVisible}
        mode={'side'}
        onClose={handleModalClose}>
        <EquipmentLister
          language={store.ui.language}
          currency={store.ui.currency}
          size={store.ui.windowSize}
          data={
            store.equipment.data[getArrayItemIndexById(store.equipment.data, detailsSelected)]
          }></EquipmentLister>
      </Modal>
    </div>
  );

  function handleInfoClick(id) {
    setModalVisible(true);
    setDetailsSelected(id);
  }

  function handleModalClose() {
    setModalVisible(false);
    setDetailsSelected(null);
  }

  function handleNextButtonClick() {
    setRoute('/accessories');
  }

  function groupTabData(listData) {
    let groupedData = [];
    let groupedObject = {};

    listData.forEach((item) => {
      if (item.group) {
        groupedObject[item.group] = groupedObject[item.group] ? groupedObject[item.group] : {};

        groupedObject[item.group].label = item.group;
        groupedObject[item.group].value = item.group;
      }
    });

    groupedData = Object.values(groupedObject);

    return Object.values(groupedData);
  }

  function groupDropdownData(listData) {
    let groupedData = [];
    let groupedObject = {};

    listData.forEach((item) => {
      if (item.subcategory) {
        groupedObject[item.subcategory] = groupedObject[item.subcategory]
          ? groupedObject[item.subcategory]
          : {};

        groupedObject[item.subcategory].label = item.subcategory;
        groupedObject[item.subcategory].value = item.subcategory;
      }
    });

    groupedData = Object.values(groupedObject);

    return Object.values(groupedData);
  }

  function translateDropdownData(translations, dropdownData) {
    dropdownData.forEach((data, i) => {
      dropdownData[i].label = translations[dropdownData[i].label];
    });

    return dropdownData;
  }

  function getIndexById(listData, id) {
    for (let i = 0; i < listData.length; i++) {
      if (listData[i].id === id) return i;
    }
  }

  function filterDataByGroup(data, group) {
    if (group === 'all') return data;

    return data.filter((item) => {
      return item.group === group ? true : false;
    });
  }

  function filterDataBySubcategory(data, subcategory) {
    if (subcategory === 'all') return data;

    return data.filter((item) => {
      return item.subcategory === subcategory ? true : false;
    });
  }

  function handleTabSelect(category) {
    setSelectedCategory(category);
  }

  function getUserOptionsId(storeUserData) {
    let option = [];

    return storeUserData.parameters &&
      storeUserData.parameters.option &&
      Object.keys(storeUserData.parameters.option).length
      ? Object.keys(store.user.data.parameters.option).map((key) => parseInt(key))
      : option;
  }

  function handleItemRemove(id) {
    dispatch(removeOption(id));
  }

  function handleItemSelect(id, forceSend = false) {
    if (id === null) {
      setItemChanging(null);
      return;
    }

    let selection = listData.filter((data) => {
      return data.id === id ? true : false;
    });

    if (selection.length) {
      if (forceSend) {
        // Event is calling api
        if (selection[0].change) {
          // Change  so different api call
          dispatch(changeOption(id));
          setItemChanging(null);
          dispatch(setGalleryIndex(0));
        } else {
          // No change
          dispatch(selectOption(id));
          setItemChanging(null);
          dispatch(setGalleryIndex(0));
        }
      } else {
        if (selection[0].change) {
          // Only opening dialog
          setItemChanging(id);
        } else {
          dispatch(selectOption(id));
          setItemChanging(null);
          dispatch(setGalleryIndex(0));
        }
      }
    }
  }
}
