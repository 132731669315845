import React, { useState } from 'react';
import Nicon from '../Nicon/Nicon';
import Button from '../Button/Button';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';
import { useTranslation } from 'react-i18next';

import styles from './EquipmentCard.module.scss';

export default function EquipmentCard(props) {
  let {
    size,
    language,
    currency,
    id,
    selected,
    image,
    name,
    starting_price,
    info_left,
    info_right,
    offersLink,
    onPropertiesClick: onPropertiesClickCb,
    onSelectClick: onSelectClickCb,
  } = props;
  let { t } = useTranslation('translation');
  let style = new Style(styles);
  let [specListOpen, setSpecListOpen] = useState(false);
  let currencyFormatter = formatter(language, currency);

  return (
    <div
      className={style.css(
        'equipment-card',
        selected && 'equipment-card--selected',
        size && `equipment-card--${size}`,
        specListOpen ? 'equipment-card--open' : ''
      )}>
      <div className={style.css('cardeal')}>
        <div className={style.css('cardeal__image')}>
          <img src={image} alt='Micra' />
        </div>
        <div className={style.css('cardeal__offer')}>
          <h2>{name}</h2>
          <div className={style.css('cardeal__offer-super')}>
            {t('translation.Common.Starting price', 'Kezdőár')}
          </div>
          <div className={style.css('cardeal__offer-price')}>
            {currencyFormatter.format(starting_price)}
          </div>
          {offersLink && (
            <div
              className={style.css('cardeal__offer-link')}
              onClick={(e) => handleOffersLinkClick(offersLink)}>
              {t('translation.Common.View offers', 'Ajánlatok megtekintése')}
            </div>
          )}
          <div className={style.css('cardeal__offer-warning')}>
            {t(
              'translation.Common.Some versions are limited',
              'Egyes verziók korlátozottan érhetők el. További információért kérjük forduljon márkakereskedőjéhez.'
            )}
          </div>
        </div>
      </div>
      <div className={style.css('specs')}>
        <div className={style.css('specs__list')}>
          <div className={style.css('specs__list-header')} onClick={handleListHeaderClick}>
            <h3>{t('translation.Common.Main features', 'Fő jellemzők')}</h3>
          </div>
          <div
            className={style.css(
              'specs__list-wrapper',
              specListOpen ? 'specs__list-wrapper--open' : ''
            )}>
            <div className={style.css('specs__columns')}>
              <div
                className={style.css('specs__left')}
                dangerouslySetInnerHTML={{ __html: info_left }}></div>
              <div
                className={style.css('specs__right')}
                dangerouslySetInnerHTML={{ __html: info_right }}></div>
            </div>
          </div>
        </div>
        <div className={style.css('specoffer')}>
          <div className={style.css('specoffer__super')}>
            {t('translation.Common.Starting price', 'Kezdőár')}
          </div>
          <div className={style.css('specoffer__price')}>
            {currencyFormatter.format(starting_price)}
          </div>
        </div>
        <div className={style.css('actions')}>
          <div className={style.css('actions__link')} onClick={(e) => handlePropertiesClick(id)}>
            {t('translation.Common.All features', 'Az összes jellemző')}{' '}
            <Nicon size={11} icon={'chevright'} />
            <div className={style.css('nicon nikon-wedge')}></div>
          </div>
          <div className={style.css('actions__button')}>
            <Button
              onClick={(e) => handleSelectClick(id)}
              color={selected ? 'gray' : 'red'}
              icon={'chevron'}>
              {t('translation.Common.Selection', 'Kiválasztás')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  function handleOffersLinkClick(offersLink) {
    let win = window.open(offersLink, '_blank');
    win.focus();
  }

  function handleListHeaderClick() {
    setSpecListOpen(!specListOpen);
  }

  function handlePropertiesClick(id) {
    onPropertiesClickCb && onPropertiesClickCb(id);
  }

  function handleSelectClick(id) {
    onSelectClickCb && onSelectClickCb(id);
  }
}
