import React from 'react';
import { useTranslation } from 'react-i18next';
import Nicon from '../Nicon/Nicon';
import Button from '../Button/Button';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';

import styles from './ChangeInfo.module.scss';

export default function ChangeInfo(props) {
  let {
    open,
    hideTop,
    size,
    language,
    currency,
    className,
    arrowPos,
    id,
    equipment,
    equipmentPrice,
    sum,
    removeList,
    replaceList,
    handleTopClick: handleTopClickCb,
    handleCancelClick: handleCancelClickCb,
    handleAgreeClick: handleAgreeClickCb,
    onInfoClick,
  } = props;

  let { t } = useTranslation('translation');
  let style = new Style(styles);

  let currencyFormatter = formatter(language, currency);

  return (
    <div
      className={style.compose(
        style.css('change-info', size && `change-info--${size}`),
        className
      )}>
      <div className={style.css('arrow__wrapper')}>
        <div
          className={style.css('arrow__content')}
          style={{
            '--changeinfo-arrow-left': arrowPos ? `${arrowPos}%` : '50%',
          }}></div>
      </div>
      {!hideTop && (
        <div className={style.css('change-info__top')} onClick={() => handleTopClick(open)}>
          <div className={style.css('heading')}>
            <div className={style.css('heading__text')}>
              {t('translation.Common.Equipment change', 'Felszereltség módosítása')} <br />
              {equipmentPrice && (
                <>
                  <strong>{equipment}</strong> {currencyFormatter.format(equipmentPrice)}
                </>
              )}
            </div>
            <div
              className={style.css('heading__icon')}
              onClick={onInfoClick ? () => onInfoClick(id) : null}>
              <Nicon size={14} icon={'info'} />
            </div>
          </div>
        </div>
      )}
      <div className={style.css('change-info__bottom', open && 'change-info__bottom--open')}>
        <div className={style.css('remove')}>
          <div className={style.css('list')}>
            <div className={style.css('list__title')}>
              {t('translation.Common.Remove selection', 'Kijelölés eltávolítása')}
            </div>
            {removeList &&
              removeList.map((item, index) => (
                <div className={style.css('list__item')} key={index}>
                  <div className={style.css('list__left')}>{item.name}</div>
                  <div className={style.css('list__right')}>
                    {currencyFormatter.format(item.price)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={style.css('change')}>
          <div className={style.css('list')}>
            <div className={style.css('list__title')}>
              {t('translation.Common.Change selection', 'Kijelölés cseréje')}
            </div>
            {replaceList &&
              replaceList.map((item, index) => (
                <div className={style.css('list__item')} key={index}>
                  <div className={style.css('list__left')}>{item.name}</div>
                  <div className={style.css('list__right')}>
                    {currencyFormatter.format(item.price)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={style.css('price')}>
          <div className={style.css('price__label')}>
            {t('translation.Common.New grand total', 'Új végösszeg')}
          </div>
          <div className={style.css('price__value')}>{currencyFormatter.format(sum)}</div>
        </div>
        <div className={style.css('actions')}>
          <div className={style.css('actions__left')}>
            <Button color={'gray'} icon={'chevron'} onClick={handleCancelClick}>
              {t('translation.Common.Cancel', 'Mégse')}
            </Button>
          </div>
          <div className={style.css('actions__right')}>
            <Button color={'red'} icon={'chevron'} onClick={handleAgreeClick}>
              {t('translation.Common.Comfirmation', 'Megerősítés')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  function handleTopClick(open) {
    handleTopClickCb && handleTopClickCb();
  }

  function handleAgreeClick() {
    handleAgreeClickCb && handleAgreeClickCb();
  }

  function handleCancelClick() {
    handleCancelClickCb && handleCancelClickCb();
  }
}
