import React, { useState } from 'react';
import Style from '../../libs/style';

import styles from './Dropdown.module.scss';

export default function Dropdown(props) {
  let {
    size,
    className,
    data,
    defaultValue,
    selected: propsSelected,
    onSelect: onSelectCb,
  } = props;
  let [open, setOpen] = useState(false);
  let [selected, setSelected] = useState(defaultValue);

  let style = new Style(styles);

  return (
    <div
      className={style.compose(
        style.css('dropdown', size ? `dropdown--${size}` : '', open ? `dropdown--open` : ''),
        className ? className : ''
      )}>
      <div className={style.css('select')} onClick={handleOpen}>
        {getItemByValue(data, propsSelected ? propsSelected : selected).label}
      </div>
      <div className={style.css('list')}>
        <div className={style.css('list__content')}>
          <ul>
            {data.map((item, index) => (
              <li key={index} onClick={() => handleSelect(item.value)}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  function handleSelect(value) {
    setSelected(value);
    setOpen(false);
    onSelectCb && onSelectCb(value);
  }

  function handleOpen() {
    setOpen(!open);
  }

  function getItemByValue(data, value) {
    let items = data.filter((item) => {
      return item.value === value ? true : false;
    });

    return items.length ? items[0] : {};
  }
}
