import { setLanguage, setCurrency } from './ui';
import { matchDomainToURL } from '../libs/domain';
import { getArrayItemById } from '../libs/helper';

export function fetchDomain() {
  return (dispatch) => {
    fetch(`${window.App.config.apiURL}/domain`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setDomainData(data.data));

        let matchingDomain = matchDomainToURL(data.data);

        if (matchingDomain !== null) {
          let domain = getArrayItemById(data.data, matchingDomain);

          dispatch(setCurrent(matchingDomain));
          dispatch(setLanguage(domain.lang));
          dispatch(setCurrency(domain.deviza));
        } else {
          dispatch(setCurrent(data.data[0].id));
          dispatch(setLanguage('hu'));
          dispatch(setCurrency('HUF'));
        }
      });
  };
}

export function setDomainData(data) {
  return {
    type: 'SET_DOMAIN_DATA',
    payload: data,
  };
}

export function setCurrent(domain) {
  return {
    type: 'SET_DOMAIN_CURRENT',
    payload: domain,
  };
}
