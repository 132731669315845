import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from '../hooks/useRouter';
import {
  setMenuScrollVisible,
  setMenuScrollX,
  setMenuScrollIndex,
  setWindowSize,
} from '../actions/ui';
import { clearMenuSelection } from '../actions/menu';
import { fetchDomain } from '../actions/domain';
import { setSummaryPrint } from '../actions/summary';
import { fetchUserSnapshot } from '../actions/user';

export function useMenu() {
  let { setRoute, location } = useRouter();
  let dispatch = useDispatch();
  let store = useSelector((store) => store);
  let [saveModalVisible, setSaveModalVisible] = useState(false);
  let [shareModalVisible, setShareModalVisible] = useState(false);

  let handleResetClick = () => {
    dispatch(clearMenuSelection());
    dispatch({ type: 'EXIT' });
    dispatch(fetchDomain());
    dispatch(setWindowSize(window.innerWidth, window.innerHeight));
    setRoute('/');
  };

  let handlePrintClick = () => {
    dispatch(setSummaryPrint(true));
    setRoute('/summary');
  };

  let handleSaveClick = () => {
    dispatch(
      fetchUserSnapshot(store.user.token, () => {
        setSaveModalVisible(true);
      })
    );
  };

  let handleShareClick = () => {
    dispatch(
      fetchUserSnapshot(store.user.token, () => {
        setShareModalVisible(true);
      })
    );
  };

  let handleSaveModalClose = () => {
    setSaveModalVisible(false);
  };

  let handleShareModalClose = () => {
    setShareModalVisible(false);
  };

  let handleStepClick = (route) => {
    if (location.pathname === '/equipment') {
      if (!store.equipment.selection) return;
    }

    if (location.pathname === '/engine') {
      if (route !== '/equipment') {
        if (!store.engine.selection) return;
      }
    }

    setRoute(route);
  };

  let handleScrollChange = (value) => {
    dispatch(setMenuScrollX(value));
  };

  let handleScrollVisibility = (visibility) => {
    if (typeof visibility !== 'undefined') dispatch(setMenuScrollVisible(visibility));
  };

  let handleScrollIndexChange = (value) => {
    dispatch(setMenuScrollIndex(value));
  };

  return {
    saveModalVisible,
    shareModalVisible,
    setSaveModalVisible,
    handleSaveClick,
    handlePrintClick,
    handleShareClick,
    handleSaveModalClose,
    handleShareModalClose,
    handleStepClick,
    handleScrollChange,
    handleScrollVisibility,
    handleScrollIndexChange,
    handleResetClick,
  };
}
