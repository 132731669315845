import React from 'react';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';
import ListDrawer from '../../components/ListDrawer/ListDrawer';
import TitleBox from '../../components/TitleBox/TitleBox';
import { useTranslation } from 'react-i18next';

import styles from './EquipmentLister.module.scss';

export default React.memo(function EquipmentLister(props) {
  let { size, language, currency, data, title, titlebox } = props;
  let style = new Style(styles);
  let { t } = useTranslation('translation');
  let currencyFormatter = formatter(language, currency);

  let name;

  if (title) {
    name = title;
  } else if (data) {
    name = data.name;
  }

  return (
    <div className={style.css('equipment-lister', size && `equipment-lister--${size}`)}>
      <div className={style.css('top')}>
        <div className={style.css('top__super')}>
          {t('translation.Common.Change to following', 'Változtatás a következőre')}:
        </div>
        <div className={style.css('top__equipment')}>{name}</div>
        <div className={style.css('top__price')}>
          {data && currencyFormatter.format(data.starting_price)}
        </div>
        {titlebox && <TitleBox className={style.css('top__box')} {...titlebox} />}
      </div>
      <div className={style.css('lists')}>
        <div className={style.css('lists__items')}>
          <div className={style.css('lists__scroller')}>
            {data &&
              data.details &&
              data.details.map((detail, index) => (
                <ListDrawer key={index} size={size} detail={detail} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});
