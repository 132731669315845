import React from 'react';
import Style from '../../libs/style';

import styles from './Button.module.scss';

export default function Button(props) {
  let { color, icon, className, onClick } = props;
  let style = new Style(styles);

  let compositeClass = style.compose(
    style.css(
      'button',
      color ? `button--${color}` : '',
      icon ? `button--icon` : '',
      icon ? `button--icon-${icon}` : ''
    ),
    className && className
  );

  return (
    <button className={compositeClass} onClick={onClick && onClick}>
      {props.children}
    </button>
  );
}
