import React, { useState } from 'react';
import Style from '../../libs/style';

import styles from './SpecificationUList.module.scss';
let style = new Style(styles);

export default function SpecificationUList(props) {
  let { size, title, open: propsOpen, onHeadClick: onHeadClickCb } = props;
  let [open, setOpen] = useState(propsOpen);

  return (
    <div className={style.css('spec-list', size ? `spec-list--${size}` : '')}>
      <div className={style.css('head')} onClick={onHeadClick}>
        <div className={style.css('head-title')}>{title}</div>
        <div className={style.css('head-toggle')}>&#xE66C;</div>
      </div>
      <div className={style.css('body', open ? 'body--open' : '')}>
        <List {...props} />
      </div>
    </div>
  );

  function onHeadClick() {
    setOpen(!open);
    onHeadClickCb && onHeadClickCb();
  }
}

function List(props) {
  let { ulist } = props;

  return ulist ? (
    <>
      {ulist.map((item, index) => (
        <div key={`${index}`} className={style.css('list')}>
          <div className={style.css('list__title')}>{item.title}</div>
          <div
            key={`${index}`}
            className={style.css('list__items')}
            dangerouslySetInnerHTML={{ __html: item.body }}></div>
        </div>
      ))}
    </>
  ) : (
    <></>
  );
}
