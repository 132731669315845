import React, { useState, useEffect, isValidElement, cloneElement, useRef } from 'react';
import ChangeInfo from '../ChangeInfo/ChangeInfo';
import Style from '../../libs/style';
import { closestParent } from '../../libs/dom';

import styles from './ItemListColors.module.scss';

export default function ItemListColors(props) {
  let ref = useRef();
  let {
    size,
    language,
    currency,
    selected,
    itemChanging,
    itemsPerRow: propsItemsPerRow,
    listData,
    className,
    handleSelect,
    onInfoClick,
  } = props;

  let [itemsPerRow, setItemsPerRow] = useState(3);
  let [itemChangingArrow, setItemChangingArrow] = useState(50);

  let style = new Style(styles);

  let numOfRows = Math.ceil(listData.length / itemsPerRow);

  // Calculating items per row
  useEffect(() => {
    let calculatedItemsPerRow = getItemsPerRow(propsItemsPerRow, size);
    if (calculatedItemsPerRow === itemsPerRow) return;
    setItemsPerRow(calculatedItemsPerRow);
  }, [size]);

  return (
    <div
      className={style.compose(
        style.css('item-list', size ? `item-list--${size}` : ''),
        className ? className : ''
      )}>
      {(() => {
        let rows = [];

        for (let i = 0; i < numOfRows; i++) {
          let items = [];
          let changeInRow = false;

          rows.push(
            <div className={style.compose(style.css('item__row'), 'row')} key={`item-${i}`}>
              {(() => {
                for (let i2 = i * itemsPerRow; i2 < (i + 1) * itemsPerRow; i2++) {
                  if (!listData[i2]) break;
                  if (listData[i2].id === itemChanging) {
                    changeInRow = true;
                  }

                  items.push(
                    <div className={getBootstrapClass(itemsPerRow)} key={`item-${i2}`}>
                      <div className={style.css('item-wrapper')} ref={ref}>
                        {isValidElement(listData[i2].elem) &&
                          cloneElement(listData[i2].elem, {
                            selected: selected.includes(listData[i2].id),
                            handleSelect: (e) => handleItemClick(e, listData[i2]),
                          })}
                      </div>
                    </div>
                  );
                }

                return items;
              })()}

              {(() => {
                if (!changeInRow) return;

                let item = getItemById(listData, itemChanging);

                return (
                  <div className={style.compose(style.css('change-wrapper'), 'col-12')}>
                    <ChangeInfo
                      language={language}
                      currency={currency}
                      arrowPos={itemChangingArrow}
                      className={style.css('item__changeinfo')}
                      {...item.change}
                      open={true}
                      hideTop={false}
                      onInfoClick={onInfoClick && onInfoClick}
                      handleAgreeClick={(e) => handleAgreeClick(item.id, true)}
                      handleCancelClick={() => handleCancelClick()}
                    />
                  </div>
                );
              })()}
            </div>
          );
        }

        return rows;
      })()}
    </div>
  );

  function getBootstrapClass(itemsPerRow) {
    return `col-${12 / itemsPerRow}`;
  }

  function getItemsPerRow(colSize, size) {
    if (!colSize) return 'col-3 col-sm-4';

    let cols = ['xs', 'sm', 'md', 'lg', 'xl'];
    let itemsPerRow = 3;

    for (const col of cols) {
      if (typeof colSize[col] !== 'undefined') {
        itemsPerRow = colSize[col];
        if (size === col) break;
      }
    }

    return itemsPerRow;
  }

  function getItemById(listData, id) {
    for (const data of Object.values(listData)) {
      if (data.id === id) return data;
    }
  }

  function handleItemClick(event, item) {
    if (item.change) {
      // Calculatin arrow position

      // Getting the closest row for clicked item
      let parentRow = closestParent(event.target, '.row');

      // Parent row width (px)
      let parentRowWidth = parentRow.offsetWidth;

      // The arrow moving range (px)
      // Parent row - 15px item margins - 14px moving range padding
      let arrowSliderWidth = parentRowWidth - 29;

      // What percentage is the arrow range of the parent row (%)
      let arrowSliderRowPercentage = Math.round(arrowSliderWidth / (parentRow.offsetWidth / 100));

      // How smaller is the arrow range (%)
      let arrowSliderRowDifference = 100 - arrowSliderRowPercentage;

      // Single item width (%)
      let itemWidth = 100 / itemsPerRow;

      // Which item in row is the current item
      let itemIndexInRow = getIndexInRow(listData, item.id, itemsPerRow);

      // Where it the current items center on the row range
      // Current item width * which item in row - the items widths half so its the center (%)
      let itemCenter = itemWidth * itemIndexInRow - itemWidth / 2;

      // Creating a virtual range compensated by the row/arrow range difference
      // Row range position calculated on compensated range
      let positionOnCompensatedRange = itemCenter + (itemCenter / 100) * arrowSliderRowDifference;

      // Since the arrow range is centered we need to subtract the half of difference
      let arrowPosition = positionOnCompensatedRange - arrowSliderRowDifference / 2;

      setItemChangingArrow(arrowPosition);
      handleSelect(item.id);
    } else {
      handleSelect(item.id, true);
    }
  }

  function getIndexInRow(listData, itemId, itemsPerRow) {
    let numOfRows = Math.ceil(listData.length / itemsPerRow);

    for (let i = 0; i < numOfRows; i++) {
      let numberInRow = 0;

      for (let i2 = i * itemsPerRow; i2 < (i + 1) * itemsPerRow; i2++) {
        numberInRow++;
        if (itemId === listData[i2].id) {
          return numberInRow;
        }
      }
    }
  }

  function handleAgreeClick(id, forceSend = false) {
    handleSelect(id, forceSend);
  }

  function handleCancelClick() {
    handleSelect(null);
  }
}
