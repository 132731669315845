let defaultData = {
  data: {},
  relatedCars: [],
  dataUpdated: null,
  print: false,
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_SUMMARY_DATA':
      return {
        ...state,
        data: action.payload,
        dataUpdated: Date.now(),
      };
    case 'SET_SUMMARY_RELATED_CARS':
      return {
        ...state,
        relatedCars: action.payload,
        dataUpdated: Date.now(),
      };
    case 'SET_SUMMARY_PRINT':
      return {
        ...state,
        print: action.payload,
      };
    default:
      return { ...state };
  }
}
