import React from 'react';
import { useTranslation } from 'react-i18next';
import Style from '../../libs/style';
import Nicon from '../Nicon/Nicon';
import { formatter } from '../../libs/helper';

import styles from './SummaryCarCard.module.scss';

export default function SummaryCarCard(props) {
  let { className, language, currency } = props;
  let style = new Style(styles);
  let currencyFormatter = formatter(language, currency);
  let { t } = useTranslation('translation');

  return (
    <a
      href={props.car.url}
      className={style.compose(style.css('summary-car-card'), className)}
      target='_blank'
      rel='noreferrer noopener'>
      <div className={style.compose(style.css('summary-car-card__image'), className)}>
        <img src={props.car.image} alt={props.car.model_name} />
      </div>
      <div className={style.compose(style.css('summary-car-card__content'), className)}>
        <h2 className={style.compose(style.css('summary-car-card__title'), className)}>
          {props.car.model_name}
        </h2>
        <div className={style.compose(style.css('summary-car-card__dealer'), className)}>
          {props.car.dealer_city
            ? `${props.car.dealer_city}, ${props.car.dealer_name}`
            : props.car.dealer_name}
        </div>
        <div className={style.compose(style.css('summary-car-card__text'), className)}>
          {props.car.eim_description}
        </div>
        <div className={style.compose(style.css('summary-car-card__link'), className)}>
          {t('translation.Common.Details', 'Részletek')}
          <Nicon size={11} icon={'chevright'} />
        </div>
        <div className={style.compose(style.css('summary-car-card__price'), className)}>
          {currencyFormatter.format(props.car.price)}
        </div>
      </div>
    </a>
  );
}
