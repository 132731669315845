import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getIndexByRoute } from '../libs/menu';

function useRouter() {
  let history = useHistory();
  let store = useSelector((store) => store);
  let location = useLocation();
  let params = useParams();

  function setRoute(route, next) {
    history.push(route);
    next && next();
  }

  let menuIndex = useMemo(() => {
    return getIndexByRoute(store.menu, location.pathname);
  }, [location.pathname]);

  return {
    location,
    params,
    menuIndex,
    setRoute,
  };
}

export { useRouter };
