export function setActiveByRoute(route) {
  return {
    type: 'SET_ACTIVE_BY_ROUTE',
    payload: route,
  };
}
export function setActiveByIndex(index) {
  return {
    type: 'SET_ACTIVE_BY_INDEX',
    payload: index,
  };
}

export function setMenuSelection(route, selection) {
  return {
    type: 'SET_MENU_SELECTION',
    payload: { route, selection },
  };
}

export function clearMenuSelection() {
  return {
    type: 'CLEAR_MENU_SELECTION',
  };
}

export function syncUserToMenu(user) {
  return {
    type: 'SYNC_USER_TO_MENU',
    payload: user,
  };
}
