let defaultData = {
  data: [],
  current: null,
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_DOMAIN_DATA':
      return {
        ...state,
        data: action.payload,
      };
    case 'SET_DOMAIN_CURRENT':
      return {
        ...state,
        current: action.payload,
      };

    default:
      return { ...state };
  }
}
