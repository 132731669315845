import React, { useState } from 'react';
import Style from '../../libs/style';

import styles from './Overlay.module.scss';
let style = new Style(styles);

export default function Overlay(props) {
  let { size } = props;

  return (
    <div className={style.css('overlay', size ? `overlay--${size}` : '')}>
      <div className={style.css('overlay__wrapper')}>{props.children}</div>
    </div>
  );
}
