import { useSelector, useDispatch } from 'react-redux';
import { setModellSelection } from '../actions/modell';
import { setEquipmentSelection } from '../actions/equipment';
import { setEngineSelection } from '../actions/engine';
import { fetchUserCreate, setUserToken } from '../actions/user';

function useDevelopment() {
  let store = useSelector((store) => store);
  let dispatch = useDispatch();

  function setDefaults(modellId, equipmentId, engineId) {
    modellId && dispatch(setModellSelection(modellId));
    equipmentId && dispatch(setEquipmentSelection(equipmentId));
    engineId && dispatch(setEngineSelection(engineId));
  }

  function createDevUser(engineId) {
    if (store.user.token === null) {
      dispatch(fetchUserCreate(engineId));
    }
  }

  function setDevUser(token) {
    if (store.user.token === null) {
      dispatch(setUserToken(token));
    }
  }

  return {
    setDefaults,
    createDevUser,
    setDevUser,
  };
}

export { useDevelopment };
