import React, { useMemo, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Style from '../../libs/style';
import { getArrayItemIndexById, langISOToLang } from '../../libs/helper';
import { getFirstImage } from '../../libs/data';
import { useRouter } from '../../hooks/useRouter';
import { useMenu } from '../../hooks/useMenu';
import { useStore } from '../../hooks/useStore';
// import { useDevelopment } from '../../hooks/useDevelopment';
import { setEquipmentSelection } from '../../actions/equipment';
import { fetchEngine, setEngineSelection, fetchEngineChange } from '../../actions/engine';
import { setMenuSelection } from '../../actions/menu';
import { fetchUserCreate } from '../../actions/user';
import MainMenu from '../../components/MainMenu/MainMenu';
import EngineCard from '../../components/EngineCard/EngineCard';
import EquipmentLister from '../../components/EquipmentLister/EquipmentLister';
import Modal from '../../components/Modal/Modal';
import ModalSave from '../../components/ModalSave/ModalSave';
import get from 'lodash/get';

import styles from './EnginePage.module.scss';
let style = new Style(styles);

export default function EnginePage(props) {
  let { size } = props;
  let { menuIndex, setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();
  let { userData } = useStore();
  // let { setDefaults, setDevUser } = useDevelopment();
  let { t } = useTranslation('translation');
  let {
    saveModalVisible,
    shareModalVisible,
    handleSaveClick,
    handlePrintClick,
    handleShareClick,
    handleSaveModalClose,
    handleShareModalClose,
    handleStepClick,
    handleScrollChange,
    handleScrollVisibility,
    handleScrollIndexChange,
    handleResetClick,
  } = useMenu();

  let [modalVisible, setModalVisible] = useState(false);
  let [engineSelected, setEngineSelected] = useState(null);

  // If no modell selected, redirect to selection
  useLayoutEffect(() => {
    // setDefaults(1, 1, 1);
    // if (!store.user.token) setDevUser('10jr351a');

    // Custom event
    window.parent.postMessage(
      JSON.stringify({
        type: 'step_data',
        step: 'step2-motor',
      }),
      '*'
    );

    if (!store.equipment.selection) {
      setRoute('/equipment');
    }
  }, []);

  // If user already has selected or change occured we update local state
  useLayoutEffect(() => {
    if (store.user.data.category && store.user.data.category.id) {
      dispatch(setEquipmentSelection(store.user.data.category.id));
    }
    if (store.user.data.level && store.user.data.level.id) {
      dispatch(dispatch(setEngineSelection(store.user.data.level.id)));
    }
  }, [store.user.dataUpdated]);

  // Fetching equipment
  useLayoutEffect(() => {
    if (!store.domain.current) return;
    dispatch(fetchEngine(store.modell.selection, store.equipment.selection));
  }, [store.domain.current, store.modell.selection, store.equipment.selection]);

  let engineSelectedData =
    store.engine.data[getArrayItemIndexById(store.engine.data, engineSelected)];
  let modellSelectedData =
    store.modell.data[getArrayItemIndexById(store.modell.data, store.modell.selection)];

  return (
    <div className={style.css('equipment-page', size ? `equipment-page--${size}` : '')}>
      <div className={style.css('menu')}>
        <MainMenu
          language={store.ui.language}
          index={menuIndex}
          scrollX={store.ui.menuScrollX}
          scrollIndex={store.ui.menuScrollIndex}
          scrollVisible={store.ui.menuScrollVisible}
          size={store.ui.windowSize}
          data={useMemo(() => store.menu, [store.menu])}
          onStepClick={handleStepClick}
          onPrintClick={store.user.token ? handlePrintClick : null}
          onSaveClick={store.user.token ? handleSaveClick : null}
          onShareClick={store.user.token ? handleShareClick : null}
          onScrollChange={handleScrollChange}
          onScrollIndexChange={handleScrollIndexChange}
          onScrollVisibility={handleScrollVisibility}
          onResetClick={handleResetClick}
        />
      </div>
      <div className={style.css('main')}>
        <div className={style.css('content-wrapper')}>
          <div className={style.css('content-scroll')}>
            <div className={style.css('info')}>
              <div className={style.css('info__title')}>
                {t('translation.Common.Engine', 'Motor')}
              </div>
              <div className={style.css('info__current')}>
                {t('translation.Common.Currently selected', 'Jelenleg kiválasztva')}:{' '}
                <strong>
                  {(() => {
                    let index = getArrayItemIndexById(store.engine.data, store.engine.selection);

                    return (
                      index !== null &&
                      store.engine.data[
                        getArrayItemIndexById(store.engine.data, store.engine.selection)
                      ].name
                    );
                  })()}
                </strong>
              </div>
            </div>
            <div className='engines'>
              <div className='row'>
                {store.engine.data.map((item) => (
                  <div className='col-sm-6 col-lg-4' key={item.id}>
                    <EngineCard
                      language={store.ui.language}
                      currency={store.ui.currency}
                      selected={store.engine.selection && store.engine.selection === item.id}
                      {...item}
                      modellSelectedData={modellSelectedData}
                      size={store.ui.windowSize}
                      onSelect={handleCardSelect}
                      onInformationClick={handleCardInfoClick}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {shareModalVisible && (
        <Modal
          className={style.css('share-modal')}
          size={store.ui.windowSize}
          visible={true}
          mode={'side'}
          onClose={handleShareModalClose}>
          <iframe
            className={style.css('share-modal__iframe')}
            src={`https://ni-content.hu/nissan_config/${langISOToLang(
              store.ui.language
            )}/share?model=${get(userData, 'modell.name')}&shareURL=${encodeURIComponent(
              `${get(userData, 'domain.share_url')}?token=${store.user.token}`
            )}&image=${encodeURIComponent(getFirstImage(store.user.data))}`}
            title='Nissan share'></iframe>
        </Modal>
      )}
      {saveModalVisible && (
        <Modal
          size={store.ui.windowSize}
          excolor={'#c3002f'}
          visible={true}
          onClose={handleSaveModalClose}>
          <ModalSave
            url={`${get(userData, 'domain.share_url')}?token=${get(userData, 'user.token')}`}
            size={store.ui.windowSize}
          />
        </Modal>
      )}
      <Modal
        size={store.ui.windowSize}
        visible={modalVisible}
        mode={'side'}
        onClose={handleModalClose}>
        <EquipmentLister
          size={store.ui.windowSize}
          language={store.ui.language}
          currency={store.ui.currency}
          title={`${get(engineSelectedData, 'name', '')} ${get(engineSelectedData, 'fuel', '')}`}
          titlebox={{
            title: t(
              'translation.Common.Fuel consumption and CO2 emission',
              'Üzemanyag-fogyasztás és CO2 kibocsátás'
            ),
            dataList: [
              {
                title: get(engineSelectedData, 'fuel_consumption')
                  ? t(
                      'translation.Common.Fuel Consumption - Mixed (WLTP)',
                      'Üzemanyagfogyasztás - Vegyes (WLTP)'
                    )
                  : !get(engineSelectedData, 'fuel_consumption') && get(engineSelectedData, 'range')
                  ? t(
                      'translation.Common.Range (WLTP) - Combined',
                      'Hatótávolság (WLTP), Kombinált'
                    )
                  : '',

                value: get(engineSelectedData, 'fuel_consumption')
                  ? get(engineSelectedData, 'fuel_consumption', '')
                  : !get(engineSelectedData, 'fuel_consumption') && get(engineSelectedData, 'range')
                  ? get(engineSelectedData, 'range')
                  : '',
              },
              {
                title: t(
                  'translation.Common.CO2 emissions - Mixed (WLTP)',
                  'CO2 kibocsátás - Vegyes (WLTP)'
                ),
                value: get(engineSelectedData, 'co2', ''),
              },
            ],
          }}
          data={engineSelectedData}></EquipmentLister>
      </Modal>
    </div>
  );

  function handleCardInfoClick(id) {
    setModalVisible(true);
    setEngineSelected(id);
  }

  function handleModalClose() {
    setModalVisible(false);
  }

  function handleCardSelect(id) {
    dispatch(setEngineSelection(id));

    dispatch(
      setMenuSelection(
        '/engine',
        store.engine.data[getArrayItemIndexById(store.engine.data, id)].name
      )
    );

    if (!store.user.token) {
      dispatch(
        fetchUserCreate(id, () => {
          setRoute('/color');
        })
      );
    } else {
      if (store.engine.selection === id) {
        setRoute('/color');
      } else {
        dispatch(
          fetchEngineChange(id, () => {
            setRoute('/color');
          })
        );
      }
    }
  }
}
