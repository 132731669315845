import React, { useRef, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import tippy from 'tippy.js';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';

import styles from './Swatch.module.scss';
let style = new Style(styles);

export default function Swatch(props) {
  let {
    size,
    color,
    name,
    price,
    language,
    currency,
    image,
    shine,
    padding,
    selected,
    handleSelect,
  } = props;
  let ref = useRef();
  let currencyFormatter = formatter(language, currency);
  let tooltip;

  useEffect(() => {
    // No tooltip is swatch is selected
    if (selected) return;

    tooltip && tooltip.destroy && tooltip.destroy();
    tooltip = tippy(ref.current, {
      appendTo: 'parent',
      allowHTML: true,
      content: ReactDOMServer.renderToStaticMarkup(
        <div className={style.css('tooltip')}>
          <div className={style.css('tooltip__title')}>{name ? name : ''}</div>
          <div className={style.css('tooltip__price')}>
            {currencyFormatter.format(price ? price : 0)}
          </div>
        </div>
      ),
      arrow: true,
    });

    return () => {
      tooltip.destroy();
    };
  }, [name, price, selected]);

  let inlineStyle = {
    backgroundColor: color ? color : null,
    backgroundImage: image ? `url(${image})` : null,
    paddingBottom: padding ? `${padding}%` : null,
  };

  return (
    <div
      ref={ref}
      onClick={handleSelect}
      className={style.css(
        'swatch',
        size ? `swatch--${size}` : '',
        image ? 'swatch--image' : '',
        selected ? 'swatch--selected' : ''
      )}
      style={inlineStyle}>
      {shine && <div className={style.css(shine ? 'swatch__shine' : '')}></div>}
    </div>
  );
}
