export function fetchUserCreate(engineId, done) {
  return (dispatch, getState) => {
    fetch(`${window.App.config.apiURL}/${getState().domain.current}/user/create?motor=${engineId}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserData(data));
        done();
      });
  };
}

export function fetchUserData(token) {
  return (dispatch, getState) => {
    fetch(`${window.App.config.apiURL}/${getState().domain.current}/user?token=${token}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserFetchDataError(null));
        dispatch(setUserData({ user: data }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setUserFetchDataError());
      });
  };
}

export function fetchUserSnapshot(token, done) {
  return (dispatch, getState) => {
    fetch(
      `${window.App.config.apiURL}/${getState().domain.current}/user/snapshot?token=${token}`
    ).then(() => {
      done();
    });
  };
}

export function setUserToken(data) {
  return {
    type: 'SET_USER_TOKEN',
    payload: data,
  };
}

export function setUserData(data) {
  return {
    type: 'SET_USER_DATA',
    payload: data,
  };
}

export function setUserFetchDataError(error = Date.now()) {
  return {
    type: 'SET_USER_FETCH_DATA_ERROR',
    payload: error,
  };
}

export function resetUser() {
  return {
    type: 'RESET_USER',
  };
}

export function fetchUserDestroy() {
  return (dispatch, getState) => {
    fetch(`${window.App.config.apiURL}/${getState().domain.current}/user/destroy`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setUserData(data));
      });
  };
}
