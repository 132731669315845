import React, { useState } from 'react';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';

import styles from './SpecificationList.module.scss';
let style = new Style(styles);

export default function SpecificationList(props) {
  let { size, language, currency, title, open: propsOpen, onHeadClick: onHeadClickCb } = props;
  let [open, setOpen] = useState(propsOpen);

  return (
    <div className={style.css('spec-list', size ? `spec-list--${size}` : '')}>
      <div className={style.css('head')} onClick={onHeadClick}>
        <div className={style.css('head-title')}>{title}</div>
        <div className={style.css('head-toggle')}>&#xE66C;</div>
      </div>
      <div className={style.css('body', open ? 'body--open' : '')}>
        <List {...props} />
      </div>
    </div>
  );

  function onHeadClick() {
    setOpen(!open);
    onHeadClickCb && onHeadClickCb();
  }
}

function List(props) {
  let { lists } = props;

  return lists ? (
    <>
      {lists.map((item, index) => (
        <div key={`${index}`} className={style.css('list', item.type ? `list--${item.type}` : '')}>
          <div className={style.css('list__title')}>{item.title}</div>
          {item.items.map((listItems, index2) => (
            <div key={`${index}-${index2}`} className={style.css('list__items')}>
              <div className={style.css('list__definition')}>{listItems.name}</div>
              <div className={style.css('list__value')}>{listItems.value}</div>
            </div>
          ))}
        </div>
      ))}
    </>
  ) : (
    <></>
  );
}
