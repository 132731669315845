import React from 'react';
import Style from '../../libs/style';

import styles from './LoaderIcon.module.scss';
let style = new Style(styles);

export default function LoaderIcon(props) {
  let { size, animation, color } = props;

  return (
    <div
      className={style.css(
        'loader-icon',
        size ? `loader-icon--${size}` : '',
        animation ? `loader-icon--a-${animation}` : '',
        color ? `loader-icon--c-${color}` : ''
      )}
      style={{ width: size + 'px', height: size + 'px' }}>
      {props.children}
    </div>
  );
}
