import React from 'react';
import Style from '../../libs/style';

import styles from './TitleBox.module.scss';

export default React.memo(function TitleBox(props) {
  let { size, className, title, dataList } = props;
  let style = new Style(styles);

  return (
    <div className={style.compose(style.css('title-box', size && `title-box--${size}`), className)}>
      <div className={style.css('title-box__title')}>{title}</div>
      <div className={style.css('list')}>
        {dataList &&
          dataList.map((data, index) => (
            <div key={index} className={style.css('list__item')}>
              <div className={style.css('list__name')}>{data.title}</div>
              <div className={style.css('list__value')}>{data.value}</div>
            </div>
          ))}
      </div>
    </div>
  );
});
