import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from '../../hooks/useRouter';
import Overlay from '../../components/Overlay/Overlay';
import LoaderIcon from '../../components/LoaderIcon/LoaderIcon';
import { fetchUserData } from '../../actions/user';
import { fetchModells, setModellSelection } from '../../actions/modell';
import { fetchEquipments, setEquipmentSelection } from '../../actions/equipment';
import { fetchEngine, setEngineSelection } from '../../actions/engine';
import { fetchColorData } from '../../actions/color';
import { fetchUpholsteryData } from '../../actions/upholstery';
import { fetchOptionData } from '../../actions/option';
import { fetchAccessoryData } from '../../actions/accessory';
import Style from '../../libs/style';

import { ReactComponent as NissanLogo } from '../../assets/images/logo_nissan_flat.svg';

import styles from './LoadPage.module.scss';
let style = new Style(styles);

export default function LoadPage() {
  let firstRun = useRef(true);
  let { params, setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserData(params.token));
  }, []);

  useEffect(() => {
    if (firstRun.current) return;

    // If the token didn't return any user
    if (store.user.userFetchDataError !== null) {
      setRoute('');
      return;
    }

    // Modell
    dispatch(fetchModells());
    dispatch(setModellSelection(store.user.data.car.id));

    // Equipment
    dispatch(fetchEquipments(store.user.data.car.id));
    dispatch(setEquipmentSelection(store.user.data.category.id));

    // Engine
    dispatch(fetchEngine(store.user.data.car.id, store.user.data.category.id));
    dispatch(setEngineSelection(store.user.data.level.id));

    // Color
    dispatch(fetchColorData());

    // Upholstery
    dispatch(fetchUpholsteryData());

    // Options
    dispatch(fetchOptionData());

    // Accesories
    dispatch(fetchAccessoryData());

    // Finaly the Summary
    setRoute('/summary');
  }, [store.user.userFetchDataError, store.user.dataUpdated]);

  useEffect(() => {
    firstRun.current = false;
  }, []);

  return (
    <div className={style.css('wheels-page', `wheels-page--${store.ui.windowSize}`)}>
      <Overlay visible={true}>
        <LoaderIcon size={50} animation={'spin'} className={style.css('loader-icon')}>
          <NissanLogo />
        </LoaderIcon>
      </Overlay>
    </div>
  );
}
