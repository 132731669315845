import React, { useEffect, useState } from 'react';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';
import { useTranslation } from 'react-i18next';

import styles from './InfoImage.module.scss';

export default function InfoImage(props) {
  let {
    size,
    big,
    galleryMode = true,
    language,
    currency,
    exteriorImage,
    interiorImage,
    modell,
    equipment,
    engine,
    wheels,
    transmission,
    price,
    handleMaximizeClick: handleMaximizeClickCb,
    handleLinkClick: handleLinkClickCb,
  } = props;

  let { t } = useTranslation('translation');
  let style = new Style(styles);
  let [bigTab, setBigTab] = useState(galleryMode);

  useEffect(() => {
    if (interiorImage.length > 0) {
      if (!galleryMode) {
        bigTab && setBigTab(false);
      } else {
        !bigTab && setBigTab(true);
      }
    } else {
      !bigTab && setBigTab(true);
    }
  }, [interiorImage]);

  return (
    <div
      className={style.css(
        'info-image',
        size ? `info-image--${size}` : '',
        big ? `info-image--big` : ''
      )}>
      <div className={style.css('button__maximize')} onClick={handleMaximizeClick}></div>
      <div className={style.css('info')}>
        <div className={style.css('info__name')}>{modell}</div>
        <div className={style.css('info__engine')}>
          {equipment} {engine} {transmission} {wheels}
        </div>
        <div className={style.css('info__super')}>
          {t('translation.Common.Total price', 'Teljes ár')}
        </div>
        <div className={style.css('info__price')}>
          {formatter(language, currency).format(price)}
        </div>
      </div>
      <div className={style.css('image')}>
        <div
          className={style.css('image__img')}
          style={{ backgroundImage: `url(${bigTab ? exteriorImage : interiorImage})` }}></div>
      </div>
      {big && (
        <div className={style.css('controls')}>
          <div
            className={style.css('controls__btn-external', bigTab && 'controls__btn--active')}
            onClick={() => handleTabClick(true)}>
            {t('translation.Common.External', 'Külső')}
          </div>
          <div
            className={style.css('controls__btn-internal', !bigTab && 'controls__btn--active')}
            onClick={() => handleTabClick(false)}>
            {t('translation.Common.Internal', 'Belső')}
          </div>
        </div>
      )}
    </div>
  );

  function handleTabClick(bool) {
    setBigTab(bool);
  }

  function handleMaximizeClick() {
    if (galleryMode === true) setBigTab(true);
    handleMaximizeClickCb && handleMaximizeClickCb();
  }
}
