import React from 'react';
import { useTranslation } from 'react-i18next';
import Nicon from '../Nicon/Nicon';
import Style from '../../libs/style';

import styles from './MainMenuStep.module.scss';

export default React.memo(function MainMenuStep(props) {
  let { route, size, selection, active, inverted, icon, onStepClick: onStepClickCb } = props;

  let style = new Style(styles);
  let { t } = useTranslation('translation');

  let stepClass = style.css(
    'step',
    size ? `step--${size}` : '',
    active ? 'step--active' : '',
    inverted ? 'step--inverted' : ''
  );

  let translations = {
    '/equipment': t('translation.Common.Equipment level', 'Felszereltségi szint'),
    '/engine': t('translation.Common.Engine', 'Motor'),
    '/color': t('translation.Common.Color', 'Szín'),
    '/upholstery': t('translation.Common.Upholstery', 'Kárpit'),
    '/rims': t('translation.Common.Rims', 'Keréktárcsák'),
    '/options': t('translation.Common.Packages and options', 'Csomagok és opciók'),
    '/accessories': t('translation.Common.Accessories', 'Tartozékok'),
    '/summary': t('translation.Common.Summary', 'Összegzés'),
  };

  return (
    <div className={stepClass} onClick={(e) => handleStepClick(route)}>
      <div className={styles['step__icon']}>
        <Nicon size={20} icon={icon} />
      </div>
      <div className={styles['step__text']}>
        <div className={styles['step__text-title']}>{translations[route]}</div>
        <div className={styles['step__text-selection']}>{selection}</div>
      </div>
    </div>
  );

  function handleStepClick(route) {
    onStepClickCb && onStepClickCb(route);
  }
});
