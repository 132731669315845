import React from 'react';
import { useTranslation } from 'react-i18next';
import Nicon from '../Nicon/Nicon';
import Price from '../Price/Price';
import Style from '../../libs/style';
import { formatter, getArrayItemIndexById } from '../../libs/helper';

import styles from './SummaryList.module.scss';
import AutoHitelWidget from '../AutoHitelWidget/AutoHitelWidget';
import { useSelector } from 'react-redux';
let style = new Style(styles);

export default function SummaryList(props) {
  let {
    size,
    language,
    currency,
    className,
    priceValue,
    calculatorLink,
    onLinkClick: onLinkClickCb,
    onResetClick: onResetClickCb,
  } = props;

  let { t } = useTranslation('translation');

  let store = useSelector((store) => store);

  let modellSelectedData =
    store.modell.data[getArrayItemIndexById(store.modell.data, store.modell.selection)];
  let engineSelectedData =
    store.engine.data[getArrayItemIndexById(store.engine.data, store.engine.selection)];

  return (
    <div
      className={style.compose(
        style.css('summary-list', size ? `summary-list--${size}` : ''),
        className
      )}>
      <SummaryListItems {...props} />
      <div className={style.css('price')}>
        <div className={style.css('price__price')}>
          <Price
            price={priceValue}
            title={t('translation.Common.Total price', 'Teljes ár')}
            language={language}
            currency={currency}
          />
        </div>
        {language === 'hu' && (
          <div className={style.css('price__widget')}>
            <AutoHitelWidget
              bruttoVetelar={priceValue}
              regisztraciosAdo={
                engineSelectedData?.registration_tax != null
                  ? engineSelectedData?.registration_tax
                  : 0
              }
              akcioKod={
                engineSelectedData?.el_modell != null ? engineSelectedData?.el_modell : ''
              }></AutoHitelWidget>
          </div>
        )}
      </div>
      {calculatorLink && (
        <div className={style.css('calc')} onClick={onLinkClick}>
          <Nicon size={14} icon={'calc'} />
          <span className={style.css('calc__text')}>
            {t('translation.Common.Financing calculator', 'Finanszírozási kalkulátor')}
          </span>
          <Nicon size={14} icon={'chevright'} />
        </div>
      )}
      <div className={style.css('tos')}>
        <span className={style.css('reset__text')}>
          {t(
            'translation.Common.Prices of articles',
            'A tartozékok árai nem tartalmazzák a felszerelési és egyéb kapcsolódó költségeket'
          )}
        </span>
      </div>
      <div className={style.css('reset')} onClick={onResetClick}>
        <Nicon size={14} icon={'reload'} />
        <span className={style.css('reset__text')}>
          {t('translation.Common.Reset configuration', 'Konfiguráció alaphelyzetbe állítása')}
        </span>
      </div>
    </div>
  );

  function onLinkClick() {
    onLinkClickCb && onLinkClickCb();
  }

  function onResetClick() {
    onResetClickCb && onResetClickCb();
  }
}

function SummaryListItems(props) {
  let { items, language, currency, onEditClick: onEditClickCb } = props;
  let currencyFormatter = formatter(language, currency);

  return (
    <>
      {items &&
        items.map((item, index) => (
          <div className={style.css('item')} key={index}>
            <div className={style.css('item__title')} onClick={onEditClick}>
              <span>{item.title}</span>
              <Nicon size={12} icon={'edit'} />
            </div>
            {item.name?.length > 0 && (
              <div className={style.css('item__lines')}>
                <div className={style.css('item__line')}>
                  <div className={style.css('item__name')}>{item.name}</div>
                  <div className={style.css('item__price')}>
                    <div className={style.css('item__super')}>{item.itemSuper}</div>
                    <div className={style.css('item__value')}>
                      {currencyFormatter.format(item.itemValue)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {item.items?.length > 0 && (
              <div className={style.css('item__lines')}>
                {item.items.map((ItemsItem, index2) => (
                  <div className={style.css('item__line')} key={index2}>
                    <div className={style.css('item__name')}>{ItemsItem.name}</div>
                    <div className={style.css('item__price')}>
                      <div className={style.css('item__value')}>
                        {currencyFormatter.format(ItemsItem.price)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </>
  );

  function onEditClick() {
    onEditClickCb && onEditClickCb();
  }
}
