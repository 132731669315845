export function fetchModells() {
  return (dispatch, getState) => {
    fetch(`${window.App.config.apiURL}/${getState().domain.current}/car`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setModellData(data.data));
      });
  };
}

export function setModellData(data) {
  return {
    type: 'SET_MODELL_DATA',
    payload: data,
  };
}

export function setModellSelection(id) {
  return {
    type: 'SET_MODELL_SELECTION',
    payload: id,
  };
}
