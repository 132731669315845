import React from 'react';
import { useTranslation } from 'react-i18next';
import Nicon from '../Nicon/Nicon';
import Button from '../Button/Button';
import Style from '../../libs/style';

import styles from './ModalReset.module.scss';

export default function ModalReset(props) {
  let { size, onButtonClick: onButtonClickCb } = props;

  let { t } = useTranslation('translation');
  let style = new Style(styles);

  return (
    <div className={style.compose(style.css('modal-reset', size ? `modal-reset--${size}` : ''))}>
      <div className={style.css('modal-reset__title')}>
        {t('translation.Common.Reset configuration', 'Konfiguráció alaphelyzetbe állítása')}
      </div>
      <div className={style.css('modal-reset__icon')}>
        <Nicon icon={'warning'} size={70} className={style.css('modal-reset__nicon')} />
      </div>
      <div className={style.css('modal-reset__warning')}>
        {t(
          'translation.Common.Are you sure you want to remove the previously selected options',
          'Biztosan el szeretné távolítani az előzőleg kiválasztott lehetőségeket?'
        )}
      </div>
      <div className={style.css('modal-reset__action')}>
        <Button
          onClick={handleButtonClick}
          color={'red'}
          icon={'chevron'}
          className={style.css('modal-reset__botton')}>
          {t('translation.Common.Reset configuration', 'Konfiguráció alaphelyzetbe állítása')}
        </Button>
      </div>
    </div>
  );

  function handleButtonClick() {
    onButtonClickCb && onButtonClickCb();
  }
}
