import React, { useLayoutEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Style from '../../libs/style';
import { setWindowSize } from '../../actions/ui';
import { fetchDomain } from '../../actions/domain';
import { setIframeHeight } from '../../libs/helper';
import ModellPage from '../../pages/ModellPage/ModellPage';
import EquipmentPage from '../../pages/EquipmentPage/EquipmentPage';
import EnginePage from '../../pages/EnginePage/EnginePage';
import ColorPage from '../../pages/ColorPage/ColorPage';
import UpholsteryPage from '../../pages/UpholsteryPage/UpholsteryPage';
import RimsPage from '../../pages/RimsPage/RimsPage';
import OptionsPage from '../../pages/OptionsPage/OptionsPage';
import AccessoriesPage from '../../pages/AccessoriesPage/AccessoriesPage';
import SummaryPage from '../../pages/SummaryPage/SummaryPage';
import LoadPage from '../../pages/LoadPage/LoadPage';
import Overlay from '../../components/Overlay/Overlay';
import LoaderIcon from '../../components/LoaderIcon/LoaderIcon';
import { ReactComponent as NissanLogo } from '../../assets/images/logo_nissan_flat.svg';

import '../../assets/styles/variables.scss';
import '../../assets/styles/reset.scss';
import '../../assets/styles/grid.scss';
import '../../assets/styles/typography.scss';
import '../../assets/styles/global.scss';
import styles from './App.module.scss';

import 'tippy.js/dist/tippy.css';

import createI18 from '../../i18n';

let style = new Style(styles);

function App() {
  let appRef = useRef();
  let domain = useSelector((store) => store.domain.current);
  let language = useSelector((store) => store.ui.language);
  let currency = useSelector((store) => store.ui.currency);
  let dispatch = useDispatch();

  // Getting domain data
  // Window resize watcher for responsive data
  useLayoutEffect(() => {
    dispatch(fetchDomain());

    dispatch(setWindowSize(window.innerWidth, window.innerHeight));

    let resizeHandler = debounce((e) => {
      dispatch(setWindowSize(window.innerWidth, window.innerHeight));
    }, 300);

    window.addEventListener('resize', resizeHandler);
  }, []);

  // Setting language
  useLayoutEffect(() => {
    createI18(language, currency);
  }, [language, currency]);

  // Iframe resize
  useLayoutEffect(() => {
    function outputsize(e) {
      let observerHeight = e?.[0]?.contentRect?.height;
      let appHeight =
        observerHeight !== null || observerHeight !== undefined ? observerHeight : '1000';

      setIframeHeight(appHeight);
    }

    new ResizeObserver(outputsize).observe(appRef.current);
  }, []);

  // Setting language
  useLayoutEffect(() => {
    createI18(language, currency);
  }, [language, currency]);

  return (
    <div className={style.css('app')} ref={appRef}>
      {domain ? (
        <Router>
          <Switch>
            <Route path='/load/:token'>
              <LoadPage />
            </Route>
            <Route path='/summary'>
              <SummaryPage />
            </Route>
            <Route path='/accessories'>
              <AccessoriesPage />
            </Route>
            <Route path='/options'>
              <OptionsPage />
            </Route>
            <Route path='/rims'>
              <RimsPage />
            </Route>
            <Route path='/upholstery'>
              <UpholsteryPage />
            </Route>
            <Route path='/color'>
              <ColorPage />
            </Route>
            <Route path='/equipment'>
              <EquipmentPage />
            </Route>
            <Route path='/engine'>
              <EnginePage />
            </Route>
            <Route path='/'>
              <ModellPage />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Overlay visible={true}>
          <LoaderIcon size={50} animation={'spin'} className={style.css('loader-icon')}>
            <NissanLogo />
          </LoaderIcon>
        </Overlay>
      )}
    </div>
  );
}

export default App;
