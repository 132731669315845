export function matchDomainToURL(data) {
  let host = window.location.host;
  let itemId = null;

  data.forEach((item) => {
    if (item.domain === host) itemId = item.id;
  });

  return itemId;
}
