import React, { useMemo, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Style from '../../libs/style';
import { getArrayItemIndexById, langISOToLang } from '../../libs/helper';
import { getFirstImage } from '../../libs/data';
import { useRouter } from '../../hooks/useRouter';
import { useMenu } from '../../hooks/useMenu';
import { useStore } from '../../hooks/useStore';
import { useDevelopment } from '../../hooks/useDevelopment';
import { useTranslation } from 'react-i18next';
import { setToastEquipment } from '../../actions/ui';
import { resetUser } from '../../actions/user';
import { setEngineSelection } from '../../actions/engine';
import { fetchEquipments, setEquipmentSelection } from '../../actions/equipment';
import { setMenuSelection, clearMenuSelection } from '../../actions/menu';
import MainMenu from '../../components/MainMenu/MainMenu';
import EquipmentCard from '../../components/EquipmentCard/EquipmentCard';
import EquipmentLister from '../../components/EquipmentLister/EquipmentLister';
import Modal from '../../components/Modal/Modal';
import ModalSave from '../../components/ModalSave/ModalSave';
import Toast from '../../components/Toast/Toast';
import get from 'lodash/get';

import styles from './EquipmentPage.module.scss';
let style = new Style(styles);

export default function EquipmentPage(props) {
  let { size } = props;
  let { menuIndex, setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();
  let { userData } = useStore();
  let { setDefaults, setDevUser } = useDevelopment();
  let { t } = useTranslation('translation');
  let {
    saveModalVisible,
    shareModalVisible,
    handleSaveClick,
    handlePrintClick,
    handleShareClick,
    handleSaveModalClose,
    handleShareModalClose,
    handleStepClick,
    handleScrollChange,
    handleScrollVisibility,
    handleScrollIndexChange,
    handleResetClick,
  } = useMenu();

  let [modalVisible, setModalVisible] = useState(false);
  let [detailsSelected, setDetailsSelected] = useState(null);

  // If no modell selected, redirect to selection
  useLayoutEffect(() => {
    // setDefaults(1, 1, 1);
    // if (!store.user.token) setDevUser('gxasfff4');

    // Custom event
    window.parent.postMessage(
      JSON.stringify({
        type: 'step_data',
        step: 'step1-equipment',
      }),
      '*'
    );

    if (!store.modell.selection) {
      setRoute('/');
    }
  }, []);

  // If user already has selected or change occured we update local state
  useLayoutEffect(() => {
    if (store.user.data.category && store.user.data.category.id) {
      dispatch(setEquipmentSelection(store.user.data.category.id));
    }
  }, [store.user.dataUpdated]);

  // Fetching equipment
  useLayoutEffect(() => {
    if (!store.domain.current) return;
    dispatch(fetchEquipments(store.modell.selection));
  }, [store.domain.current, store.modell.selection]);

  return (
    <div className={style.css('equipment-page', size ? `equipment-page--${size}` : '')}>
      <div className={style.css('menu')}>
        <MainMenu
          language={store.ui.language}
          index={menuIndex}
          scrollX={store.ui.menuScrollX}
          scrollIndex={store.ui.menuScrollIndex}
          scrollVisible={store.ui.menuScrollVisible}
          size={store.ui.windowSize}
          data={useMemo(() => store.menu, [store.menu])}
          onStepClick={handleStepClick}
          onPrintClick={store.user.token ? handlePrintClick : null}
          onSaveClick={store.user.token ? handleSaveClick : null}
          onShareClick={store.user.token ? handleShareClick : null}
          onScrollChange={handleScrollChange}
          onScrollIndexChange={handleScrollIndexChange}
          onScrollVisibility={handleScrollVisibility}
          onResetClick={handleResetClick}
        />
      </div>
      <div className={style.css('main')}>
        <div className={style.css('content-wrapper')}>
          <div className={style.css('content-scroll')}>
            <div className={style.css('info')}>
              <div className={style.css('info__title')}>
                {t('translation.Common.Equipment level', 'Felszereltségi szint')}
              </div>
              <div className={style.css('info__current')}>
                {t('translation.Common.Currently selected', 'Jelenleg kiválasztva')}:{' '}
                <strong>
                  {(() => {
                    let index = getArrayItemIndexById(
                      store.equipment.data,
                      store.equipment.selection
                    );

                    return (
                      index !== null &&
                      store.equipment.data[
                        getArrayItemIndexById(store.equipment.data, store.equipment.selection)
                      ].name
                    );
                  })()}
                </strong>
              </div>
            </div>
            {store.ui.toastEquipment && (
              <Toast
                title={t('translation.Common.Equipment level', 'Felszereltségi szint')}
                info={t(
                  'translation.Common.Here you can select the equipment for your car',
                  'Itt választhatja ki a felszereltségi szintet autójához'
                )}
                onClose={handleToastClose}
              />
            )}
            <div className={style.css('cards')}>
              {store.equipment.data.map((item) => (
                <EquipmentCard
                  key={item.id}
                  language={store.ui.language}
                  currency={store.ui.currency}
                  selected={store.equipment.selection && store.equipment.selection === item.id}
                  {...item}
                  size={store.ui.windowSize}
                  offersLink={get(userData, 'domain.offers_link')}
                  onPropertiesClick={handlePropertiesClick}
                  onSelectClick={handleSelectClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {shareModalVisible && (
        <Modal
          className={style.css('share-modal')}
          size={store.ui.windowSize}
          visible={true}
          mode={'side'}
          onClose={handleShareModalClose}>
          <iframe
            className={style.css('share-modal__iframe')}
            src={`https://ni-content.hu/nissan_config/${langISOToLang(
              store.ui.language
            )}/share?model=${get(userData, 'modell.name')}&shareURL=${encodeURIComponent(
              `${get(userData, 'domain.share_url')}?token=${store.user.token}`
            )}&image=${encodeURIComponent(getFirstImage(store.user.data))}`}
            title='Nissan share'></iframe>
        </Modal>
      )}
      {saveModalVisible && (
        <Modal
          size={store.ui.windowSize}
          excolor={'#c3002f'}
          visible={true}
          onClose={handleSaveModalClose}>
          <ModalSave
            url={`${get(userData, 'domain.share_url')}?token=${get(userData, 'user.token')}`}
            size={store.ui.windowSize}
          />
        </Modal>
      )}
      <Modal
        size={store.ui.windowSize}
        visible={modalVisible}
        mode={'side'}
        onClose={handleModalClose}>
        <EquipmentLister
          language={store.ui.language}
          currency={store.ui.currency}
          size={store.ui.windowSize}
          data={
            store.equipment.data[getArrayItemIndexById(store.equipment.data, detailsSelected)]
          }></EquipmentLister>
      </Modal>
    </div>
  );

  function handleToastClose() {
    dispatch(setToastEquipment(false));
  }

  function handlePropertiesClick(id) {
    setModalVisible(true);
    setDetailsSelected(id);
  }

  function handleSelectClick(id) {
    if (store.equipment.selection !== id) {
      dispatch(clearMenuSelection());
      dispatch(setEngineSelection(null));
      dispatch(resetUser());
    }

    dispatch(setEquipmentSelection(id));

    dispatch(
      setMenuSelection(
        '/equipment',
        store.equipment.data[getArrayItemIndexById(store.equipment.data, id)].name
      )
    );

    setRoute('/engine');
  }

  function handleModalClose() {
    setModalVisible(false);
    setDetailsSelected(null);
  }
}
