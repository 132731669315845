export function setIframeHeight(height) {
  let message = {
    type: 'resize',
    height: String(Math.round(height)),
  };

  console.log('App height now:', String(Math.round(height)));

  window.parent.postMessage(JSON.stringify(message), '*');
}

export function getArrayItemById(array, id) {
  if (!array && !id) return;
  let returnItem = null;

  array.forEach((item) => {
    if (item.id === id) returnItem = item;
  });

  return returnItem;
}

export function getArrayItemIndexById(array = [], id = null) {
  if (!array && !id) return;
  let returnIndex = null;

  array.forEach((item, index) => {
    if (item.id === id) returnIndex = index;
  });

  return returnIndex;
}

export function getArrayItemIndexByKeyValue(array, key, value) {
  if (!array || !key || !value) return;
  let returnIndex = null;

  array.forEach((item, index) => {
    if (item[key] === value) returnIndex = index;
  });

  return returnIndex;
}

export function formatter(language, currency) {
  if (currency) {
    let currencyCodes = [
      'AED',
      'AFN',
      'ALL',
      'AMD',
      'ANG',
      'AOA',
      'ARS',
      'AUD',
      'AWG',
      'AZN',
      'BAM',
      'BBD',
      'BDT',
      'BGN',
      'BHD',
      'BIF',
      'BMD',
      'BND',
      'BOB',
      'BRL',
      'BSD',
      'BTN',
      'BWP',
      'BYN',
      'BZD',
      'CAD',
      'CDF',
      'CHF',
      'CLP',
      'CNY',
      'COP',
      'CRC',
      'CUC',
      'CUP',
      'CVE',
      'CZK',
      'DJF',
      'DKK',
      'DOP',
      'DZD',
      'EGP',
      'ERN',
      'ETB',
      'EUR',
      'FJD',
      'FKP',
      'GBP',
      'GEL',
      'GGP',
      'GHS',
      'GIP',
      'GMD',
      'GNF',
      'GTQ',
      'GYD',
      'HKD',
      'HNL',
      'HRK',
      'HTG',
      'HUF',
      'IDR',
      'ILS',
      'IMP',
      'INR',
      'IQD',
      'IRR',
      'ISK',
      'JEP',
      'JMD',
      'JOD',
      'JPY',
      'KES',
      'KGS',
      'KHR',
      'KMF',
      'KPW',
      'KRW',
      'KWD',
      'KYD',
      'KZT',
      'LAK',
      'LBP',
      'LKR',
      'LRD',
      'LSL',
      'LYD',
      'MAD',
      'MDL',
      'MGA',
      'MKD',
      'MMK',
      'MNT',
      'MOP',
      'MRU',
      'MUR',
      'MVR',
      'MWK',
      'MXN',
      'MYR',
      'MZN',
      'NAD',
      'NGN',
      'NIO',
      'NOK',
      'NPR',
      'NZD',
      'OMR',
      'PAB',
      'PEN',
      'PGK',
      'PHP',
      'PKR',
      'PLN',
      'PYG',
      'QAR',
      'RON',
      'RSD',
      'RUB',
      'RWF',
      'SAR',
      'SBD',
      'SCR',
      'SDG',
      'SEK',
      'SGD',
      'SHP',
      'SLL',
      'SOS',
      'SPL',
      'SRD',
      'STN',
      'SVC',
      'SYP',
      'SZL',
      'THB',
      'TJS',
      'TMT',
      'TND',
      'TOP',
      'TRY',
      'TTD',
      'TVD',
      'TWD',
      'TZS',
      'UAH',
      'UGX',
      'USD',
      'UYU',
      'UZS',
      'VEF',
      'VND',
      'VUV',
      'WST',
      'XAF',
      'XCD',
      'XDR',
      'XOF',
      'XPF',
      'YER',
      'ZAR',
      'ZMW',
      'ZWD',
    ];

    if (!currencyCodes.includes(currency))
      throw new Error(`Currency code is not ISO 4217 (${currency})`);
  }

  let localeData = {
    hu: {
      locale: 'hu-HU',
      currency: 'HUF',
    },
    cs: {
      locale: 'cs-CZ',
      currency: 'EUR',
    },
    sk: {
      locale: 'sk-SK',
      currency: 'EUR',
    },
  };

  return new Intl.NumberFormat(language ? localeData[language].locale : 'hu-HU', {
    style: 'currency',
    currency: currency ? currency : 'HUF',
    minimumFractionDigits: 0,
  });
}

export function langISOToLang(langIso) {
  switch (langIso) {
    case 'hu':
      return 'hu';
    case 'sk':
      return 'sk';
    case 'cs':
      return 'cz';
    default:
      return langIso;
  }
}
