import React from 'react';
import Style from '../../libs/style';
import { useTranslation } from 'react-i18next';
import { formatter } from '../../libs/helper';

import styles from './TitleSelection.module.scss';

export default function TitleSelection(props) {
  let { size, language, currency, title, selection, price } = props;

  let { t } = useTranslation('translation');
  let style = new Style(styles);
  let currencyFormatter = formatter(language, currency);

  return (
    <div className={style.css('title-selection', size ? `title-selection--${size}` : '')}>
      {title && <div className={style.css('title')}>{title}</div>}
      {selection && (
        <div className={style.css('current')}>
          {t('translation.Common.Currently selected', 'Jelenleg kiválasztva')}:
        </div>
      )}
      <div className={style.css('selection')}>
        {selection && <div className={style.css('selection__title')}>{selection}</div>}
        {typeof price !== 'undefined' && (
          <div className={style.css('selection__price')}>{currencyFormatter.format(price)}</div>
        )}
      </div>
    </div>
  );
}
