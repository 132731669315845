import React from 'react';
import { useTranslation } from 'react-i18next';
import Nicon from '../Nicon/Nicon';
import Button from '../Button/Button';
import Price from '../Price/Price';
import Style from '../../libs/style';

import styles from './ListCard.module.scss';

export default function ListCard(props) {
  let {
    size,
    language,
    currency,
    selected,
    mode,
    name,
    price,
    list,
    handleSelect,
    handleRemove,
  } = props;
  let style = new Style(styles);
  let { t } = useTranslation('translation');

  return (
    <div
      className={style.css(
        'list-card',
        selected && 'list-card--selected',
        mode && `list-card--${mode}`,
        size && `list-card--${size}`
      )}>
      <div className={style.css('content')}>
        <div className={style.css('content__title')}>{name}</div>
        <div
          className={style.css('content__list')}
          dangerouslySetInnerHTML={{ __html: list }}></div>
        <Price price={price} language={language} currency={currency} />
      </div>
      <div
        className={style.css('action', selected && `action--selected`, mode && `action--${mode}`)}>
        {(() => {
          if (selected) {
            if (mode === 'included') {
              return (
                <Button color={'gray'} icon={'ex'}>
                  {t('translation.Common.Included in the list price', 'A listaár tartalmazza')}
                </Button>
              );
            } else {
              return (
                <Button color={'gray'} icon={'ex'} onClick={handleRemove}>
                  {t('translation.Common.Remove', 'Eltávolítás')}
                </Button>
              );
            }
          } else {
            return (
              <Button color={'red'} icon={'chevron'} onClick={handleSelect}>
                {t('translation.Common.Selection', 'Kiválasztás')}
              </Button>
            );
          }
        })()}
      </div>
    </div>
  );
}
