import React, { isValidElement, cloneElement } from 'react';
import ChangeInfo from '../ChangeInfo/ChangeInfo';
import Style from '../../libs/style';

import styles from './ItemListOptions.module.scss';

export default function ItemListOptions(props) {
  let {
    size,
    language,
    currency,
    className,
    listData,
    selected,
    itemChanging,
    handleSelect,
    handleRemove,
    onInfoClick,
  } = props;

  let itemsPerRow = 1;
  let numOfRows = Math.ceil(listData.length / itemsPerRow);

  let style = new Style(styles);

  return (
    <div
      className={style.compose(
        style.css('item-list', size ? `item-list--${size}` : ''),
        className ? className : ''
      )}>
      {(() => {
        let rows = [];

        for (let i = 0; i < numOfRows; i++) {
          let items = [];

          rows.push(
            <div className={style.compose(style.css('item__row'), 'row')} key={`item-${i}`}>
              {(() => {
                for (let i2 = i * itemsPerRow; i2 < (i + 1) * itemsPerRow; i2++) {
                  if (!listData[i2]) break;

                  items.push(
                    <div className={getBootstrapClass(itemsPerRow)} key={`item-${i2}`}>
                      <div className={style.css('item-wrapper')}>
                        {isValidElement(listData[i2].elem) &&
                          cloneElement(listData[i2].elem, {
                            selected: selected.includes(listData[i2].id),
                            handleSelect: (e) => handleSelect(listData[i2].id),
                            handleRemove: (e) => handleRemove(listData[i2].id),
                          })}
                      </div>
                      {listData[i2].change && (
                        <div className={style.css('change-wrapper')}>
                          <ChangeInfo
                            language={language}
                            currency={currency}
                            arrowPos={50}
                            className={style.css('item__changeinfo')}
                            {...listData[i2].change}
                            open={itemChanging === listData[i2].id}
                            onInfoClick={onInfoClick && onInfoClick}
                            handleAgreeClick={() => handleSelect(listData[i2].id, true)}
                            handleCancelClick={() => handleCancelClick()}
                          />
                        </div>
                      )}
                    </div>
                  );
                }

                return items;
              })()}
            </div>
          );
        }

        return rows;
      })()}
    </div>
  );

  function getBootstrapClass(itemsPerRow) {
    return `col-${12 / itemsPerRow}`;
  }

  function handleCancelClick() {
    handleSelect(null);
  }
}
