import React, { useState } from 'react';
import Style from '../../libs/style';

import styles from './ListTab.module.scss';

export default function ListTab(props) {
  let { size, data, defaultValue, className, onSelect: onSelectCb } = props;
  let [activeTab, setActiveTab] = useState(defaultValue);

  let style = new Style(styles);

  return (
    <div
      className={style.compose(
        style.css('list-tab', size ? `list-tab--${size}` : ''),
        className ? className : ''
      )}>
      <div className={style.css('list-tab__head')}>
        <div className={style.css('tabs')}>
          {data &&
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className={style.css(
                    'tabs__tab',
                    item.value === activeTab ? 'tabs__tab--active' : ''
                  )}
                  onClick={() => handleTabClick(item.value)}>
                  {item.label}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  function handleTabClick(value) {
    if (activeTab === value) {
      setActiveTab();
    } else {
      setActiveTab(value);
    }
    onSelectCb && onSelectCb(value);
  }
}
