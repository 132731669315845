export function fetchSummaryData() {
  return (dispatch, getState) => {
    fetch(
      `${window.App.config.apiURL}/${getState().domain.current}/summary?token=${
        getState().user.token
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(setSummaryData(data));

        fetch(data.summary.api_url)
          .then((response) => response.json())
          .then((data) => {
            dispatch(setSummaryRelatedCars(data));
          });
      });
  };
}

export function setSummaryData(data) {
  return {
    type: 'SET_SUMMARY_DATA',
    payload: data,
  };
}

export function setSummaryRelatedCars(data) {
  return {
    type: 'SET_SUMMARY_RELATED_CARS',
    payload: data,
  };
}

export function setSummaryPrint(data) {
  return {
    type: 'SET_SUMMARY_PRINT',
    payload: data,
  };
}
