import React from 'react';
import MainMenuStep from '../MainMenuStep/MainMenuStep';

import styles from './MainMenuSteps.module.scss';

export default function MainMenuSteps(props) {
  let { data, index, size, stepsRef, stepsWrapperRef, scrollX } = props;

  return (
    <div className={styles['bottom__steps']} ref={stepsRef}>
      <div
        style={{ transform: `translateX(${scrollX}px)` }}
        className={styles['bottom__steps-wrapper']}
        ref={stepsWrapperRef}>
        {data
          ? data.map((data, i) => {
              let { hidden, route, selection, inverted, icon } = data;
              return !hidden ? (
                <MainMenuStep
                  {...props}
                  key={i}
                  index={i}
                  size={size}
                  route={route}
                  selection={selection}
                  active={index === i ? true : false}
                  inverted={inverted}
                  icon={icon}
                />
              ) : null;
            })
          : null}
      </div>
    </div>
  );
}
