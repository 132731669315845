import React from 'react';
import Nicon from '../Nicon/Nicon';
import Style from '../../libs/style';

import styles from './Modal.module.scss';

export default function Modal(props) {
  let { size, className, mode, excolor, onClose: onCloseCb, visible } = props;

  let style = new Style(styles);

  return (
    <div
      className={style.compose(
        style.css(
          'modal',
          size ? `modal--${size}` : '',
          mode ? `modal--${mode}` : '',
          visible ? 'modal--visible' : ''
        ),
        className
      )}>
      <div className={style.css('modal__overlay')}>
        <div className={style.css('modal__wrapper')} onClick={handleClose}>
          <div className={style.css('modal__content')}>
            <div className={style.css('modal__ex')} style={excolor && { color: excolor }}>
              <Nicon size={20} icon={'ex'} className={style.css('close')} />
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );

  function handleClose(e) {
    let className = e.target.className;

    // Only close if clicked on X or overlay
    if (className.indexOf('close') >= 0 || className.indexOf('modal__wrapper') >= 0) {
      onCloseCb && onCloseCb();
    }
  }
}
