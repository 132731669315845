import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Style from '../../libs/style';
import { useRouter } from '../../hooks/useRouter';
import { useStore } from '../../hooks/useStore';
import ModellCard from '../../components/ModellCard/ModellCard';
import { fetchModells, setModellSelection } from '../../actions/modell';
import get from 'lodash/get';

import styles from './ModellPage.module.scss';

let style = new Style(styles);

export default function ModellPage(props) {
  let { setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();
  let { userData } = useStore();
  let { t } = useTranslation('translation');

  // Fetching equipment
  useLayoutEffect(() => {
    if (!store.domain.current) return;

    dispatch(fetchModells());
  }, [store.domain.current]);

  return (
    <div
      className={style.css(
        'modell-page',
        store.ui.windowSize ? `modell-page--${store.ui.windowSize}` : ''
      )}>
      <div className={style.css('container-wrapper')}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className={style.css('title')}>
                {t('translation.Common.Configurator', 'Konfigurátor')}
              </div>
            </div>
          </div>
          <div className='row'>
            {store.modell.data.map((modell) => (
              <div className='col-sm-6 col-md-4' key={modell.id}>
                <div className={style.css('modell-card-wrapper')}>
                  <ModellCard
                    {...modell}
                    language={store.ui.language}
                    currency={store.ui.currency}
                    onClick={handleCardClick}
                    offersLink={get(userData, 'domain.offers_link')}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  function handleCardClick(id) {
    dispatch(setModellSelection(id));
    setRoute('/equipment');
  }
}
