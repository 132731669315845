let defaultData = {
  token: null,
  data: {},
  dataUpdated: null,
  userFetchDataError: null,
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        data: action.payload.user,
        token: action.payload.user.token,
        dataUpdated: Date.now(),
      };
    case 'SET_USER_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'SET_USER_FETCH_DATA_ERROR':
      return {
        ...state,
        userFetchDataError: action.payload,
      };
    case 'RESET_USER':
      return { ...defaultData };
    default:
      return { ...state };
  }
}
