import React from 'react';
import Style from '../../libs/style';
import { formatter } from '../../libs/helper';
import { useTranslation } from 'react-i18next';

import styles from './Price.module.scss';

export default function Price(props) {
  let { price, language, currency, title } = props;
  let { t } = useTranslation('translation');
  let style = new Style(styles);
  let currencyFormatter = formatter(language, currency);

  title = title ? title : t('translation.Common.Total price', 'Teljes ár');

  return (
    <div className={style.css('price')}>
      <div className={style.css('price__title')}>{title}</div>
      <div className={style.css('price__value')}>{currencyFormatter.format(price)}</div>
    </div>
  );
}
