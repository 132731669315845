import React, { useState, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Style from '../../libs/style';
import { useTranslation } from 'react-i18next';
import { formatter, getArrayItemIndexById } from '../../libs/helper';
import AutoHitelWidget from '../AutoHitelWidget/AutoHitelWidget';

import styles from './Carousel.module.scss';

export default function Carousel(props) {
  let first = useRef(true);
  let {
    size,
    language,
    currency,
    arrows: arrowsProp,
    mode,
    index,
    images: imagesProp,
    banner,
    onMaximizeClick: onMaximizeClickCb,
    onModeClick: onModeClickCb,
    onIndexChange,
  } = props;
  let { t } = useTranslation('translation');
  let currencyFormatter = formatter(language, currency);
  let store = useSelector((store) => store);

  let [maximized, setMaximized] = useState(false);
  let [images, setImages] = useState([]);
  let [arrows, setArrows] = useState(arrowsProp ? arrowsProp : true);

  let style = new Style(styles);

  // Resetting image index on mode change
  useLayoutEffect(() => {
    if (first.current) return;
    if (index !== 0) onIndexChange(0);
  }, [mode]);

  // Syncing arrows prop with local state
  useLayoutEffect(() => {
    if (first.current) return;
    if (arrowsProp !== arrows) setArrows(arrowsProp);
  }, [arrowsProp]);

  // Sorting imageProp
  useLayoutEffect(() => {
    let images = [];

    if (get(imagesProp, `[${mode}].gallery`, []).length > 0) {
      images = imagesProp[mode].gallery;
    } else {
      images.push(get(imagesProp, '[mode].default', { src: '' }));
    }

    setImages(images);
  }, [mode, imagesProp]);

  // Preloading images
  useLayoutEffect(() => {
    let imageElems = [];

    function preload(images) {
      for (var i = 0; i < images.length; i++) {
        imageElems[i] = new Image();
        imageElems[i].src = images[i].src;
      }
    }

    if (images.length <= 1) {
      setArrows(false);
    } else {
      setArrows(true);
    }

    preload(images);
  }, [images]);

  // Hiding arrows if single image
  useLayoutEffect(() => {
    if (first.current) return;

    if (images.length <= 1) {
      setArrows(false);
    } else {
      setArrows(true);
    }
  }, [images]);

  // First?
  useLayoutEffect(() => {
    if (first.current) first.current = false;
  }, []);

  let modellSelectedData =
    store.modell.data[getArrayItemIndexById(store.modell.data, store.modell.selection)];
  let engineSelectedData =
    store.engine.data[getArrayItemIndexById(store.engine.data, store.engine.selection)];

  return (
    <div
      className={style.css(
        'carousel',
        size ? `carousel--${size}` : '',
        maximized ? 'carousel--maximized' : ''
      )}>
      <div className={style.css('carousel__wrapper')}>
        <div
          className={style.css('carousel__elem')}
          style={{
            backgroundImage: `url(${get(images, `[${index}].src`, '')})`,
          }}>
          {arrows !== false && (
            <div className={style.css('carousel__button_right')} onClick={handleNextClick}></div>
          )}
          {arrows !== false && (
            <div className={style.css('carousel__button_left')} onClick={handlePrevClick}></div>
          )}
          <div
            className={style.css(
              'carousel__button_maximize',
              maximized ? 'carousel__button_maximize--maximized' : ''
            )}
            onClick={handleMaximizeClick}></div>
          {get(imagesProp, `interior.gallery`) && (
            <div
              className={style.css('carousel__button_mode', `carousel__button_mode--${mode}`)}
              onClick={handleModeClick}></div>
          )}
          {banner && (
            <div className={style.css('info')}>
              <div className={style.css('info__text')}>
                <div className={style.css('info__type')}>{banner.modell}</div>
                <div className={style.css('info__engine')}>
                  {banner.equipment} {banner.engine} {banner.transmission} {banner.wheels}
                </div>
              </div>
              <div className={style.css('info-price')}>
                <div className={style.css('info-price__title')}>
                  {t('translation.Common.Total price', 'Teljes ár')}
                </div>
                <div className={style.css('info-price__value')}>
                  {currencyFormatter.format(banner.price)}
                </div>
                {language === 'hu' && (
                  <div className={style.css('info-price__widget')}>
                    <AutoHitelWidget
                      bruttoVetelar={banner.price}
                      regisztraciosAdo={
                        engineSelectedData?.registration_tax != null
                          ? engineSelectedData?.registration_tax
                          : 0
                      }
                      akcioKod={
                        engineSelectedData?.el_modell != null
                          ? engineSelectedData?.el_modell
                          : ''
                      }></AutoHitelWidget>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function handlePrevClick() {
    if (index - 1 < 0) {
      onIndexChange(images.length - 1);
    } else {
      onIndexChange(index - 1);
    }
  }

  function handleNextClick() {
    if (index + 1 < images.length) {
      onIndexChange(index + 1);
    } else {
      onIndexChange(0);
    }
  }

  function handleMaximizeClick() {
    setMaximized(!maximized);
    onMaximizeClickCb && onMaximizeClickCb();
  }

  function handleModeClick() {
    onModeClickCb && onModeClickCb();
  }
}
