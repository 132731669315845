import React, { useState, useRef, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import Style from '../../libs/style';

import styles from './Feature.module.scss';
let style = new Style(styles);

export default function Feature(props) {
  let first = useRef(true);
  let featureRef = useRef();
  let flierRef = useRef();
  let { size, fly } = props;

  let [animating, setAnimating] = useState(false);
  let [fixed, setFixed] = useState(false);
  let [flying, setFlying] = useState(false);

  useEffect(() => {
    if (first.current) return;

    if (flying === true) {
      let win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName('body')[0],
        winWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
        winHeight = win.innerHeight || docElem.clientHeight || body.clientHeight;

      let featureDimension = featureRef.current.getBoundingClientRect();
      let flierDimension = flierRef.current.getBoundingClientRect();

      featureRef.current.style.height = featureDimension.height + 'px';
      //featureRef.current.style.width = featureDimension.width + 'px';
      flierRef.current.style.height = flierDimension.height + 'px';
      flierRef.current.style.width = flierDimension.width + 'px';
      flierRef.current.style.top = flierDimension.top + 'px';
      flierRef.current.style.left = flierDimension.left + 'px';

      setAnimating(true);
      setFixed(true);
      anime({
        targets: flierRef.current,
        width: `${winWidth}px`,
        height: `${winHeight}px`,
        top: '0px',
        left: '0px',
        duration: 100,
        easing: 'easeInOutSine',
        complete: () => {
          setAnimating(false);
          flierRef.current.style.height = '100vh';
          flierRef.current.style.width = '100vw';
        },
      });
    } else {
      let featureDimension = featureRef.current.getBoundingClientRect();
      let flierDimension = flierRef.current.getBoundingClientRect();

      flierRef.current.style.height = flierDimension.height + 'px';
      flierRef.current.style.width = flierDimension.width + 'px';

      setAnimating(true);
      anime({
        targets: flierRef.current,
        width: `${featureDimension.width}px`,
        height: `${featureDimension.height}px`,
        top: `${featureDimension.top}px`,
        left: `${featureDimension.left}px`,
        easing: 'easeInOutSine',
        duration: 100,
        complete: () => {
          featureRef.current.style.height = '';
          flierRef.current.style.height = '';
          flierRef.current.style.width = '';
          setAnimating(false);
          setFixed(false);
        },
      });
    }
  }, [flying]);

  useEffect(() => {
    if (fly && !animating) {
      if (fly !== flying) setFlying(true);
    } else if (!fly && !animating) {
      if (fly !== flying) setFlying(false);
    }
  }, [fly, animating]);

  useEffect(() => {
    if (first) first.current = false;
  }, []);

  return (
    <div
      className={style.css('feature', size ? `feature--${size}` : '', flying ? `feature--fly` : '')}
      ref={featureRef}>
      <div
        className={style.css('flier')}
        ref={flierRef}
        style={{ position: fixed ? 'fixed' : 'static' }}>
        {props.children}
      </div>
    </div>
  );
}
