import camelCase from 'lodash/camelCase';
import get from 'lodash/get';

class Config {
  constructor() {
    window.App = { config: {} };
    this.parseProcess(process.env);
    this.setApiURL();
    return window.App.config;
  }

  // Creating global config entry from every REACT_APP ENV variable
  parseProcess(env = {}) {
    for (const item in env) {
      if (item.includes('REACT_APP')) {
        window.App.config[camelCase(item.replace('REACT_APP_', ''))] = env[item];
      }
    }
  }

  // Setting actual API URL according to NODE_ENV
  setApiURL() {
    window.App.config.apiURL =
      process.env.NODE_ENV === 'development'
        ? get(process.env, 'REACT_APP_API_URL_DEV', 'https://nissan.skvad.app/api')
        : get(process.env, 'REACT_APP_API_URL_PROD', 'https://nissan.skvad.app/api');
  }
}

export default new Config();
