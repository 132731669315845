let defaultData = {
  data: [],
  selection: null,
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_MODELL_DATA':
      return {
        ...state,
        data: action.payload,
      };
    case 'SET_MODELL_SELECTION':
      return {
        ...state,
        selection: action.payload,
      };

    default:
      return { ...state };
  }
}
