let defaultData = {
  data: {},
  dataUpdated: null,
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_COLOR_DATA':
      return {
        ...state,
        data: action.payload,
        dataUpdated: Date.now(),
      };
    case 'SET_COLOR_WHATIF':
      return setColorChange(state, action);
    default:
      return { ...state };
  }
}

function setColorChange(state, action) {
  let newState = { ...state, dataUpdated: Date.now() };

  newState.data.data[action.payload.index].additional.newGrade.removeList =
    action.payload.data.removeList;
  newState.data.data[action.payload.index].additional.newGrade.replaceList =
    action.payload.data.replaceList;

  return newState;
}
