import React, { useState, useLayoutEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';
import Nicon from '../Nicon/Nicon';
import MainMenuSteps from '../MainMenuSteps/MainMenuSteps';
import MainMenuStep from '../MainMenuStep/MainMenuStep';
import { useTranslation } from 'react-i18next';
import Style from '../../libs/style';

import styles from './MainMenu.module.scss';
let style = new Style(styles);

export default function MainMenu(props) {
  let {
    scrollX: propsScrollX,
    scrollIndex: propsScrollIndex,
    scrollVisible: propsScrollVisible,
    onSaveClick,
    onPrintClick,
    onShareClick,
    onStepClick: onStepClickCb,
    onScrollVisibility: onScrollVisibilityCb,
    onScrollChange: onScrollChangeCb,
    onScrollIndexChange: onScrollIndexChangeCb,
    onResetClick,
  } = props;
  let lastMenuItemIndex = props.data.length - 2;

  let stepsRef = useRef();
  let stepsWrapperRef = useRef();
  let { t } = useTranslation('translation');

  let [stepsWidth, setStepsWidth] = useState(null);
  let [stepsWrapperWidth, setStepsWrapperWidth] = useState(8888);
  let [scrollVisible, setScrollVisible] = useState(propsScrollVisible);
  let [scrollX, setScrollX] = useState(propsScrollX);

  useLayoutEffect(() => {
    onScrollVisibilityCb && onScrollVisibilityCb(scrollVisible);
  }, [scrollVisible]);

  // Watching for steps and steps wrapper resize
  useLayoutEffect(() => {
    let steps = stepsRef.current;
    let stepsWrapper = stepsWrapperRef.current;

    let observerStepsCallback = debounce((entries, observer) => {
      setStepsWidth(entries[0].target.offsetWidth);
    }, 300);

    let observerStepsWrapperCallback = debounce((entries, observer) => {
      setStepsWrapperWidth(entries[0].target.offsetWidth);
    }, 300);

    const resizeStepObserver = new ResizeObserver(observerStepsCallback);
    const resizeStepWrapperObserver = new ResizeObserver(observerStepsWrapperCallback);

    resizeStepObserver.observe(steps);
    resizeStepWrapperObserver.observe(stepsWrapper);

    return () => {
      resizeStepObserver.disconnect();
      resizeStepWrapperObserver.disconnect();
    };
  }, []);

  // Displaying scrollers if steps too overflow
  useLayoutEffect(() => {
    if (stepsWidth === null) return;

    var stepsWrapperWidth = stepsWrapperRef.current.offsetWidth;

    if (stepsWidth < stepsWrapperWidth) {
      setScrollVisible(true);
    } else {
      setScrollVisible(false);
      setScrollX(0);
      onScrollChangeCb(0);
    }
  }, [stepsWidth]);

  // Syncing scroll index and index when index changes
  // Not scrolling if Summary page is selected
  useLayoutEffect(() => {
    if (props.index > lastMenuItemIndex) return;
    onScrollIndexChangeCb(props.index > lastMenuItemIndex ? lastMenuItemIndex : props.index);
  }, [props.index]);

  // Scrolling the menu to the current step (index) if:
  // - index changes
  // - scroll buttons appear
  // - the width of individual steps change
  //
  // Not scrolling if Summary page is selected
  useLayoutEffect(() => {
    if (!scrollVisible) return;
    if (props.index > lastMenuItemIndex) return;

    let wrapper = stepsWrapperRef.current;
    let scrollWidth = 0;

    for (let i = 0; i < wrapper.children.length; i++) {
      if (i >= lastMenuItemIndex) break;
      if (i >= props.index) break;
      scrollWidth = scrollWidth - wrapper.children[i].offsetWidth;
    }

    if (scrollWidth !== scrollX) {
      setScrollX(scrollWidth);
      onScrollChangeCb(scrollWidth);
      onScrollIndexChangeCb(props.index > lastMenuItemIndex ? lastMenuItemIndex : props.index);
    }
  }, [lastMenuItemIndex, props.index, scrollVisible, stepsWrapperWidth]);

  return (
    <div className={style.css('main-menu', 'main-menu--' + props.size)}>
      <div className={styles['top']}>
        <div className={styles['top__toggler']}>
          <Nicon size={12} icon={'chevleft'} />
          <span className={styles['top__toggler-text']} onClick={onResetClick}>
            {t('translation.Common.Models', 'Modellek')}
          </span>
        </div>
        <div className={styles['top__steps']}>
          {props.index + 1} / {props.data.length}
        </div>
        <div className={styles['top__actions']}>
          <span
            className={style.css('action', 'action--first', !onPrintClick && 'action--hidden')}
            onClick={onPrintClick && onPrintClick}>
            <Nicon size={14} icon={'print'} />
            <span className={styles['action-text']}>
              {t('translation.Common.Printing', 'Nyomtatás')}
            </span>
          </span>
          <span
            className={style.css('action', !onShareClick && 'action--hidden')}
            onClick={onShareClick && onShareClick}>
            <Nicon size={14} icon={'share'} />
            <span className={styles['action-text']}>
              {t('translation.Common.Sharing', 'Megosztás')}
            </span>
          </span>
          <span
            className={style.css('action', !onSaveClick && 'action--hidden')}
            onClick={onSaveClick && onSaveClick}>
            <Nicon size={14} icon={'save'} />
            <span className={styles['action-text']}>
              {t('translation.Common.Saving', 'Mentés')}
            </span>
          </span>
        </div>
      </div>
      <div className={styles['bottom']}>
        <div
          onClick={handleScrollBack}
          className={style.css('bottom__back', scrollVisible ? 'bottom__back--visible' : null)}>
          <Nicon size={20} icon={'chevleft'} />
        </div>
        <MainMenuSteps
          {...props}
          scrollX={scrollX}
          stepsRef={stepsRef}
          stepsWrapperRef={stepsWrapperRef}
        />
        <div
          onClick={handleScrollForward}
          className={style.css(
            'bottom__forward',
            scrollVisible ? 'bottom__forward--visible' : null
          )}>
          <Nicon size={20} icon={'chevright'} />
        </div>
        <div className={styles['bottom__sum']}>
          <MainMenuStep
            size={props.size}
            route={'/summary'}
            inverted={true}
            icon={'checklist'}
            onStepClick={(e) => onStepClickCb('/summary')}
          />
        </div>
      </div>
    </div>
  );

  function handleScrollBack() {
    let wrapper = stepsWrapperRef.current;
    let previousChild = wrapper.children[propsScrollIndex - 1];

    if (!previousChild) return;

    let scrollWidth = previousChild.offsetWidth;

    setScrollX(scrollX + scrollWidth);
    onScrollChangeCb(scrollX + scrollWidth);
    onScrollIndexChangeCb(propsScrollIndex - 1);
    //setScrollIndex(scrollIndex - 1);
  }

  function handleScrollForward() {
    let wrapper = stepsWrapperRef.current;
    let currentChild = wrapper.children[propsScrollIndex];
    let nextChild = wrapper.children[propsScrollIndex + 1];

    if (!nextChild) return;

    let scrollWidth = currentChild.offsetWidth;

    setScrollX(scrollX - scrollWidth);
    onScrollChangeCb(scrollX - scrollWidth);
    onScrollIndexChangeCb(propsScrollIndex + 1);
    //setScrollIndex(scrollIndex + 1);
  }
}
