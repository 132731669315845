import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Style from '../../libs/style';

import styles from './AutoHitelWidget.module.scss';

export default function AutoHitelWidget(props) {
  let { bruttoVetelar = 0, regisztraciosAdo = 0, akcioKod = 0, className, onClick } = props;
  let style = new Style(styles);

  let [iframeOpen, setIframeOpen] = useState(false);
  let [widgetId] = useState(() => uuid());

  let compositeClass = style.compose(
    style.css('autohitel-widget'),
    iframeOpen && style.css('autohitel-widget--open'),
    className && className
  );

  useEffect(() => {
    function receiveMessage(event) {
      if (event.origin === 'https://www.autohitel.hu') {
        //console.log('RECIEVED: ', event);

        if (event?.data?.widgetId === widgetId) {
          if (event?.data?.style === 'iframe-expand') {
            setIframeOpen(true);
          } else if (event?.data?.style === 'iframe-shrink') {
            setIframeOpen(false);
          }
        }
      }
    }

    window.addEventListener('message', receiveMessage, false);

    return () => window.removeEventListener('message', receiveMessage, false);
  }, []);

  return (
    <div className={compositeClass} onClick={onClick && onClick}>
      <iframe
        title='autohitel'
        src={`https://www.autohitel.hu/widget/nissan/widget.html?bruttoVetelar=${bruttoVetelar}&regisztraciosAdo=${regisztraciosAdo}${akcioKod}&widgetId=${widgetId}`}
        scrolling='no'
        id={`autohitel-widget-${widgetId}`}
        frameBorder='0'></iframe>
    </div>
  );
}
