import React from 'react';
import Nicon from '../Nicon/Nicon';
import Button from '../Button/Button';
import Price from '../Price/Price';
import Style from '../../libs/style';
import { useTranslation } from 'react-i18next';

import styles from './EngineCard.module.scss';
import AutoHitelWidget from '../AutoHitelWidget/AutoHitelWidget';

export default function EngineCard(props) {
  let {
    id,
    size,
    language,
    currency,
    selected,
    registration_tax,
    el_modell,
    name,
    starting_price,
    fuel,
    power_transmission,
    driven_wheels,
    power,
    range,
    fuel_consumption,
    co2,
    onSelect,
    onInformationClick,
  } = props;
  let { t } = useTranslation('translation');
  let style = new Style(styles);

  return (
    <div
      className={style.css(
        'engine-card',
        selected && 'engine-card--selected',
        size && `engine-card--${size}`
      )}>
      <div className={style.css('engine-card__title')}>{name}</div>
      <div className={style.css('engine-card__parts')}>
        <div className={style.css('part')}>
          <Nicon size={30} icon={'pump'} className={style.css('part__nicon')} />
          <div className={style.css('part__text')}>
            <div className={style.css('part__type')}>
              {t('translation.Common.Fuel', 'Üzemanyag')}
            </div>
            <div className={style.css('part__title')}>{fuel}</div>
          </div>
        </div>
        <div className={style.css('part')}>
          <Nicon size={30} icon={'shift'} />
          <div className={style.css('part__text')}>
            <div className={style.css('part__type')}>
              {t('translation.Common.Transmission', 'Erőátvitel')}
            </div>
            <div className={style.css('part__title')}>{power_transmission}</div>
          </div>
        </div>
        <div className={style.css('part')}>
          <Nicon size={30} icon={'suspension'} />
          <div className={style.css('part__text')}>
            <div className={style.css('part__type')}>
              {t('translation.Common.Driven wheels', 'Meghajtott kerekek')}
            </div>
            <div className={style.css('part__title')}>{driven_wheels}</div>
          </div>
        </div>
      </div>
      <div className={style.css('data')}>
        <div className={style.css('data__title')}>
          <div className={style.css('data__title-text')}>
            {t('translation.Common.Specifications', 'Műszaki adatok')}
          </div>
          <div
            className={style.css('data__title-icon')}
            onClick={() => onInformationClick(id)}></div>
        </div>
        <hr />
        <div className={style.css('data__list')}>
          <div className={style.css('data__list-row')}>
            <div className={style.css('data__list-title')}>
              {t('translation.Common.Performance', 'Teljesítmény')}
            </div>
            <div className={style.css('data__list-value')}>{power}</div>
          </div>
          {fuel_consumption && (
            <div className={style.css('data__list-row')}>
              <div className={style.css('data__list-title')}>
                {t(
                  'translation.Common.Fuel Consumption - Mixed (WLTP)',
                  'Üzemanyagfogyasztás - Vegyes (WLTP)'
                )}
              </div>
              <div className={style.css('data__list-value')}>{fuel_consumption}</div>
            </div>
          )}
          {!fuel_consumption && range && (
            <div className={style.css('data__list-row')}>
              <div className={style.css('data__list-title')}>
                {t('translation.Common.Range (WLTP) - Combined', 'Hatótávolság (WLTP), Kombinált')}
              </div>
              <div className={style.css('data__list-value')}>{range}</div>
            </div>
          )}
          <div className={style.css('data__list-row')}>
            <div className={style.css('data__list-title')}>
              {t(
                'translation.Common.CO2 emissions - Mixed (WLTP)',
                'CO2 kibocsátás - Vegyes (WLTP)'
              )}
            </div>
            <div className={style.css('data__list-value')}>{co2}</div>
          </div>
        </div>
        <hr />
      </div>
      <div className={style.css('engine-card__price')}>
        <Price price={starting_price} language={language} currency={currency} />
      </div>
      {language === 'hu' && (
        <div className={style.css('engine-card__widget')}>
          <AutoHitelWidget
            bruttoVetelar={starting_price}
            regisztraciosAdo={registration_tax != null ? registration_tax : 0}
            akcioKod={
              el_modell != null ? el_modell : ''
            }></AutoHitelWidget>
        </div>
      )}
      <div className={style.css('engine-card__action')}>
        {(() => {
          switch (selected) {
            case true:
              return (
                <Button color={'gray'} icon={'chevron'} onClick={() => handleSelect(id)}>
                  {t('translation.Common.Selection', 'Kiválasztás')}
                </Button>
              );
            default:
              return (
                <Button color={'red'} icon={'chevron'} onClick={() => handleSelect(id)}>
                  {t('translation.Common.Selection', 'Kiválasztás')}
                </Button>
              );
          }
        })()}
      </div>
    </div>
  );

  function handleSelect(id) {
    onSelect && onSelect(id);
  }
}
