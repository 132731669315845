import { clearMenuSelection } from './menu';
import { setModellSelection } from './modell';
import { setEquipmentSelection } from './equipment';
import { setEngineSelection } from './engine';
import { setUserData, setUserToken } from './user';

export function setLanguage(language) {
  return {
    type: 'SET_UI_LANGUAGE',
    payload: language,
  };
}

export function setCurrency(currency) {
  return {
    type: 'SET_UI_CURRENCY',
    payload: currency,
  };
}

export function setGalleryMode(mode) {
  return {
    type: 'SET_UI_GALLERY_MODE',
    payload: mode,
  };
}

export function setGalleryIndex(index) {
  return {
    type: 'SET_UI_GALLERY_INDEX',
    payload: index,
  };
}

export function setMenuScrollVisible(visibility) {
  return {
    type: 'SET_MENU_SCROLL_VISIBLE',
    payload: visibility,
  };
}

export function setMenuScrollX(value) {
  return {
    type: 'SET_MENU_SCROLL_X',
    payload: value,
  };
}

export function setMenuScrollIndex(value) {
  return {
    type: 'SET_MENU_SCROLL_INDEX',
    payload: value,
  };
}

export function setWindowSize(width, height) {
  return {
    type: 'SET_WINDOW_SIZE',
    payload: { width, height },
  };
}

export function setToastEquipment(bool) {
  return {
    type: 'SET_TOAST_EQUIPMENT',
    payload: bool,
  };
}

export function reset(done) {
  return (dispatch) => {
    dispatch(clearMenuSelection());
    dispatch(setModellSelection(null));
    dispatch(setEquipmentSelection(null));
    dispatch(setEngineSelection(null));
    dispatch(setUserData({}));
    dispatch(setUserToken(null));
    done && done();
  };
}
