import React, { useLayoutEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Style from '../../libs/style';
import { getArrayItemIndexById, langISOToLang } from '../../libs/helper';
import { getFirstImage } from '../../libs/data';
import { useRouter } from '../../hooks/useRouter';
import { useMenu } from '../../hooks/useMenu';
import { useTranslation } from 'react-i18next';
import { useDevelopment } from '../../hooks/useDevelopment';
import { useStore } from '../../hooks/useStore';
import Button from '../../components/Button/Button';
import MainMenu from '../../components/MainMenu/MainMenu';
import TitleSelection from '../../components/TitleSelection/TitleSelection';
import Carousel from '../../components/Carousel/Carousel';
import Swatch from '../../components/Swatch/Swatch';
import ItemListColors from '../../components/ItemListColors/ItemListColors';
import InfoImage from '../../components/InfoImage/InfoImage';
import Feature from '../../components/Feature/Feature';
import Modal from '../../components/Modal/Modal';
import ModalSave from '../../components/ModalSave/ModalSave';
import EquipmentLister from '../../components/EquipmentLister/EquipmentLister';
import { setGalleryMode, setGalleryIndex } from '../../actions/ui';
import {
  fetchUpholsteryData,
  changeUpholsteryWhatIf,
  selectUpholstery,
  changeUpholstery,
} from '../../actions/upholstery';
import { fetchUserData } from '../../actions/user';
import { syncUserToMenu } from '../../actions/menu';
import get from 'lodash/get';

import styles from './UpholsteryPage.module.scss';
let style = new Style(styles);

export default function UpholsteryPage() {
  let firstRun = useRef(true);
  let { menuIndex, setRoute } = useRouter();
  let store = useSelector((store) => store);
  let dispatch = useDispatch();
  let { setDefaults, setDevUser } = useDevelopment();
  let { userData } = useStore();
  let { t } = useTranslation('translation');
  let {
    saveModalVisible,
    shareModalVisible,
    handleSaveClick,
    handlePrintClick,
    handleShareClick,
    handleSaveModalClose,
    handleShareModalClose,
    handleStepClick,
    handleScrollChange,
    handleScrollVisibility,
    handleScrollIndexChange,
    handleResetClick,
  } = useMenu();

  let [modalVisible, setModalVisible] = useState(false);
  let [detailsSelected, setDetailsSelected] = useState(null);
  let [listData, setListData] = useState([]);
  let [featureInfoImageOpen, setFeatureInfoImageOpen] = useState(false);
  let [carouselMaximized, setCarouselMaximized] = useState(false);
  let [itemChanging, setItemChanging] = useState(null);

  let handleGalleryMaximizeClick = useCallback(() => {
    setCarouselMaximized(!carouselMaximized);
  });

  let handleGalleryModeClick = useCallback(() => {
    dispatch(setGalleryMode(store.ui.galleryMode === 'exterior' ? 'interior' : 'exterior'));
  });

  let handleGalleryIndexChange = useCallback((index) => {
    dispatch(setGalleryIndex(index));
  });

  let handleInfoImageMaximizeClick = useCallback(() => {
    setFeatureInfoImageOpen(!featureInfoImageOpen);
  });

  // If no modell selected, redirect to selection
  useLayoutEffect(() => {
    // if (process.env.NODE_ENV === 'production') {
    //   if (!store.engine.selection || !store.user.token) {
    //     setRoute('/engine');
    //   }
    // } else {
    //   setDefaults(1, 1, 1);
    //   if (!store.user.token) setDevUser(1);
    // }

    // Custom event
    window.parent.postMessage(
      JSON.stringify({
        type: 'step_data',
        step: 'step4-upholstery',
      }),
      '*'
    );

    if (!store.engine.selection || !store.user.token) {
      setRoute('/engine');
    }

    // We force gallery back to external
    return () => {
      if (store.ui.galleryMode === 'interior') {
        dispatch(setGalleryMode('exterior'));
      }
    };
  }, []);

  // Force gallery mode internal
  useLayoutEffect(() => {
    let interiorGallery = get(userData, 'images.interior.gallery');

    if (!interiorGallery || !Array.isArray(interiorGallery)) return;

    if (store.ui.galleryMode !== 'interior') dispatch(setGalleryMode('interior'));
  }, [userData]);

  // Syncing user object to menu selection
  useLayoutEffect(() => {
    if (firstRun.current) return;
    dispatch(syncUserToMenu(store.user.data));
  }, [store.user.dataUpdated]);

  // Fetching upholstery data
  useLayoutEffect(() => {
    if (store.user.token === null) return;
    dispatch(fetchUpholsteryData());
  }, [store.user.token, store.engine.selection]);

  // Fetching user data
  useLayoutEffect(() => {
    if (firstRun.current) return;
    if (store.user.token === null) return;
    dispatch(fetchUserData(store.user.token));
  }, [store.user.token, store.upholstery.dataUpdated]);

  // If Item is changing we need to fetch the change data
  useLayoutEffect(() => {
    if (itemChanging === null) return;

    // Were adding what-if data to upholstery data
    let itemChangingIndex = getIndexById(listData, itemChanging);
    dispatch(changeUpholsteryWhatIf(listData[itemChangingIndex].id, itemChangingIndex));
  }, [itemChanging]);

  // Processing api response into grouped list
  useLayoutEffect(() => {
    if (!get(store, 'upholstery.data.data')) return;
    if (Object.keys(store.upholstery.data).length <= 0) return;

    let newListData = store.upholstery.data.data.map((item) => {
      return {
        id: item.id,
        group: item.group,
        elem: (
          <Swatch
            color={'#4e669c'}
            image={item.image}
            name={item.name}
            price={item.price}
            language={store.ui.language}
            currency={store.ui.currency}
          />
        ),
        change: item.additional.newGrade && {
          id: item.additional.newGrade.id,
          sum: item.additional.newGrade.starting_price,
          removeList: item.additional.newGrade.removeList,
          replaceList: item.additional.newGrade.replaceList,
        },
      };
    });

    setListData(newListData);
  }, [store.upholstery.dataUpdated]);

  useLayoutEffect(() => {
    if (firstRun.current) firstRun.current = false;
  }, []);

  let bannerData = {
    language: store.ui.language,
    currency: store.ui.currency,
    big: featureInfoImageOpen ? true : false,
    galleryMode: false,
    exteriorImage:
      get(userData, 'images.exterior.gallery[0].src') ||
      get(userData, 'images.exterior.default') ||
      '',
    interiorImage:
      get(userData, 'images.interior.gallery[0].src') ||
      get(userData, 'images.interior.default') ||
      '',
    modell: store.user.data.car && store.user.data.car.name,
    equipment: store.user.data.category && store.user.data.category.name,
    engine: store.user.data.level && store.user.data.level.motor,
    wheels: store.user.data.level && store.user.data.level.driven_wheels,
    transmission: store.user.data.level && store.user.data.level.power_transmission,
    price: store.user.data.price,
    handleMaximizeClick: handleInfoImageMaximizeClick,
  };

  return (
    <div className={style.css('upholstery-page', `upholstery-page--${store.ui.windowSize}`)}>
      <div className={style.css('menu')}>
        <MainMenu
          language={store.ui.language}
          currency={store.ui.currency}
          index={menuIndex}
          scrollX={store.ui.menuScrollX}
          scrollIndex={store.ui.menuScrollIndex}
          scrollVisible={store.ui.menuScrollVisible}
          size={store.ui.windowSize}
          data={useMemo(() => store.menu, [store.menu])}
          onStepClick={handleStepClick}
          onPrintClick={store.user.token ? handlePrintClick : null}
          onSaveClick={store.user.token ? handleSaveClick : null}
          onShareClick={store.user.token ? handleShareClick : null}
          onScrollChange={handleScrollChange}
          onScrollIndexChange={handleScrollIndexChange}
          onScrollVisibility={handleScrollVisibility}
          onResetClick={handleResetClick}
        />
      </div>
      <div className={style.css('main')}>
        <div className={style.css('left', carouselMaximized ? 'left--minimized' : '')}>
          <div className={style.css('info-image___wrapper')}>
            {store.ui.windowSize === 'xs' && (
              <Feature fly={featureInfoImageOpen}>
                <InfoImage {...bannerData} />
              </Feature>
            )}
          </div>
          <div className={style.css('colors')}>
            <div className={style.css('colors__wrapper')}>
              <div className={style.css('colors__scroll')}>
                <div className={style.css('title-selection___wrapper')}>
                  <TitleSelection
                    language={store.ui.language}
                    currency={store.ui.currency}
                    title={
                      store.ui.windowSize === 'xs'
                        ? ''
                        : t('translation.Common.Upholstery', 'Kárpit')
                    }
                    selection={getUserFirstUpholstery(store.user.data).name}
                    price={getUserFirstUpholstery(store.user.data).price}
                  />
                </div>
                {Object.values(groupListData(listData)).map((list, index) => (
                  <div key={index} className={style.css('color-list-title-wrapper')}>
                    {index > 0 && (
                      <div className={style.css('color-list-title')}>
                        {t(
                          'translation.Common.Can be reached by equipment level change',
                          'Felszereltségi szint váltással érhető el'
                        )}
                        :
                      </div>
                    )}
                    <ItemListColors
                      language={store.ui.language}
                      currency={store.ui.currency}
                      key={index}
                      size={store.ui.windowSize}
                      selected={getUserUpholsteryId(store.user.data)}
                      itemChanging={itemChanging}
                      listData={list}
                      itemsPerRow={{ xs: 4, sm: 3 }}
                      className={style.css('color-list')}
                      onInfoClick={handleInfoClick}
                      handleSelect={handleItemSelect}></ItemListColors>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={style.css('step-button')}>
            <Button text={'button'} color={'red'} icon={'chevron'} onClick={handleNextButtonClick}>
              <span style={{ fontWeight: '400' }}>
                {t('translation.Common.Next step', 'Következő lépés')}:
              </span>{' '}
              <strong>{t('translation.Common.Rims', 'Keréktárcsák')}</strong>
            </Button>
          </div>
        </div>
        <div
          className={style.css(
            'right',
            carouselMaximized ? 'right--maximized' : '',
            store.ui.windowSize === 'xs' ? 'right--hidden' : ''
          )}>
          <div className={style.css('carousel-wrapper')}>
            <Carousel
              language={store.ui.language}
              currency={store.ui.currency}
              mode={store.ui.galleryMode}
              index={store.ui.galleryIndex}
              images={userData.images}
              banner={bannerData}
              onMaximizeClick={handleGalleryMaximizeClick}
              onModeClick={handleGalleryModeClick}
              onIndexChange={handleGalleryIndexChange}
            />
          </div>
        </div>
      </div>
      {shareModalVisible && (
        <Modal
          className={style.css('share-modal')}
          size={store.ui.windowSize}
          visible={true}
          mode={'side'}
          onClose={handleShareModalClose}>
          <iframe
            className={style.css('share-modal__iframe')}
            src={`https://ni-content.hu/nissan_config/${langISOToLang(
              store.ui.language
            )}/share?model=${get(userData, 'modell.name')}&shareURL=${encodeURIComponent(
              `${get(userData, 'domain.share_url')}?token=${store.user.token}`
            )}&image=${encodeURIComponent(getFirstImage(store.user.data))}`}
            title='Nissan share'></iframe>
        </Modal>
      )}
      {saveModalVisible && (
        <Modal
          size={store.ui.windowSize}
          excolor={'#c3002f'}
          visible={true}
          onClose={handleSaveModalClose}>
          <ModalSave
            url={`${get(userData, 'domain.share_url')}?token=${get(userData, 'user.token')}`}
            size={store.ui.windowSize}
          />
        </Modal>
      )}
      <Modal
        size={store.ui.windowSize}
        visible={modalVisible}
        mode={'side'}
        onClose={handleModalClose}>
        <EquipmentLister
          language={store.ui.language}
          currency={store.ui.currency}
          size={store.ui.windowSize}
          data={
            store.equipment.data[getArrayItemIndexById(store.equipment.data, detailsSelected)]
          }></EquipmentLister>
      </Modal>
    </div>
  );

  function handleInfoClick(id) {
    setModalVisible(true);
    setDetailsSelected(id);
  }

  function handleModalClose() {
    setModalVisible(false);
    setDetailsSelected(null);
  }

  function handleNextButtonClick() {
    setRoute('/rims');
  }

  function getIndexById(listData, id) {
    for (let i = 0; i < listData.length; i++) {
      if (listData[i].id === id) return i;
    }
  }

  function getUserUpholsteryId(storeUserData) {
    let upholstery = [];

    return storeUserData.parameters &&
      storeUserData.parameters.carpet &&
      Object.keys(storeUserData.parameters.carpet).length
      ? Object.keys(store.user.data.parameters.carpet).map((key) => parseInt(key))
      : upholstery;
  }

  function getUserFirstUpholstery(storeUserData) {
    let upholstery = { name: '', price: 0 };

    return storeUserData.parameters &&
      storeUserData.parameters.carpet &&
      Object.keys(storeUserData.parameters.carpet).length
      ? storeUserData.parameters.carpet[Object.keys(storeUserData.parameters.carpet)[0]]
      : upholstery;
  }

  function groupListData(listData) {
    let groupedData = {};

    listData.forEach((item) => {
      if (typeof groupedData[item.group] !== 'undefined') {
        groupedData[item.group].push(item);
      } else {
        groupedData[item.group] = [];
        groupedData[item.group].push(item);
      }
    });

    return groupedData;
  }

  function handleItemSelect(id, forceSend = false) {
    if (id === null) {
      setItemChanging(null);
      return;
    }

    let selection = listData.filter((data) => {
      return data.id === id ? true : false;
    });

    if (selection.length) {
      if (forceSend) {
        // Event is calling api
        if (selection[0].change) {
          // Change  so different api call
          dispatch(changeUpholstery(id));
          setItemChanging(null);
          dispatch(setGalleryIndex(0));
        } else {
          // No change
          dispatch(selectUpholstery(id));
          setItemChanging(null);
          dispatch(setGalleryIndex(0));
        }
      } else {
        if (selection[0].change) {
          // Only opening dialog
          setItemChanging(id);
        } else {
          dispatch(selectUpholstery(id));
          setItemChanging(null);
          dispatch(setGalleryIndex(0));
        }
      }
    }
  }
}
