let defaultData = {
  language: 'hu',
  currency: 'HUF',
  galleryMode: 'exterior',
  galleryIndex: 0,
  menuIndexHistory: [],
  menuScrollVisible: false,
  menuScrollX: 0,
  menuScrollIndex: 0,
  windowWidth: 0,
  windowHeight: 0,
  windowSize: 'xl',
  toastEquipment: true,
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_UI_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'SET_UI_CURRENCY':
      return {
        ...state,
        currency: action.payload,
      };
    case 'SET_UI_GALLERY_MODE':
      return {
        ...state,
        galleryMode: action.payload,
      };
    case 'SET_UI_GALLERY_INDEX':
      return {
        ...state,
        galleryIndex: action.payload,
      };
    case 'SET_MENU_SCROLL_VISIBLE':
      return {
        ...state,
        menuScrollVisible: action.payload,
      };
    case 'SET_MENU_SCROLL_X':
      return {
        ...state,
        menuScrollX: action.payload,
      };
    case 'SET_MENU_SCROLL_INDEX':
      return {
        ...state,
        menuScrollIndex: action.payload,
      };
    case 'SET_WINDOW_SIZE':
      return {
        ...state,
        windowWidth: action.payload.width,
        windowHeight: action.payload.height,
        windowSize: getWindowSize(action.payload.width),
      };
    case 'SET_TOAST_EQUIPMENT':
      return {
        ...state,
        toastEquipment: action.payload,
      };

    default:
      return { ...state };
  }
}

function getWindowSize(windowWidth) {
  switch (true) {
    case windowWidth < 576:
      return 'xs';
    case windowWidth >= 576 && windowWidth < 768:
      return 'sm';
    case windowWidth >= 768 && windowWidth < 992:
      return 'md';
    case windowWidth >= 992 && windowWidth < 1200:
      return 'lg';
    case windowWidth >= 1200:
      return 'xl';
    default:
      return 'xl';
  }
}
