import React from 'react';
import Nicon from '../Nicon/Nicon';
import Price from '../Price/Price';
import Style from '../../libs/style';
import { useTranslation } from 'react-i18next';

import styles from './InfoBar.module.scss';
import { getArrayItemIndexById } from '../../libs/helper';
import { useSelector } from 'react-redux';
import AutoHitelWidget from '../AutoHitelWidget/AutoHitelWidget';

export default function InfoBar(props) {
  let {
    size,
    language,
    currency,
    title,
    subtitle,
    price,
    calculatorLink,
    onLinkClick: onLinkClickCb,
  } = props;

  let { t } = useTranslation('translation');
  let style = new Style(styles);

  let store = useSelector((store) => store);

  let modellSelectedData =
    store.modell.data[getArrayItemIndexById(store.modell.data, store.modell.selection)];
  let engineSelectedData =
    store.engine.data[getArrayItemIndexById(store.engine.data, store.engine.selection)];

  return (
    <div
      className={style.css(
        'infobar',
        size ? `infobar--${size}` : '',
        !calculatorLink ? 'infobar--2' : ''
      )}>
      <div className={style.css('infobar__text')}>
        <div className={style.css('infobar__text-title')}>{title}</div>
        <div className={style.css('infobar__text-subtitle')}>{subtitle}</div>
        <div className={style.css('infobar__text-warning')}>
          {t(
            'translation.Common.Some versions are limited',
            'Egyes verziók korlátozottan érhetők el. További információért kérjük forduljon márkakereskedőjéhez.'
          )}
        </div>
      </div>
      <div className={style.css('infobar__price', calculatorLink)}>
        <Price
          price={price}
          title={t('translation.Common.Total price', 'Teljes ár')}
          language={language}
          currency={currency}
        />
        {language === 'hu' && (
          <div className={style.css('infobar__widget', calculatorLink)}>
            <AutoHitelWidget
              bruttoVetelar={price}
              regisztraciosAdo={
                engineSelectedData?.registration_tax != null
                  ? engineSelectedData?.registration_tax
                  : 0
              }
              akcioKod={
                engineSelectedData?.el_modell != null ? engineSelectedData?.el_modell : ''
              }></AutoHitelWidget>
          </div>
        )}
      </div>
      {calculatorLink && (
        <div className={style.css('infobar__links')}>
          <div className={style.css('infobar__link')} onClick={onLinkClick}>
            <Nicon size={14} icon={'calc'} />
            {t('translation.Common.Financing calculator', 'Finanszírozási kalkulátor')}
            <Nicon size={14} icon={'chevright'} />
          </div>
        </div>
      )}
    </div>
  );

  function onLinkClick() {
    onLinkClickCb && onLinkClickCb();
  }
}
