import React from 'react';
import Style from '../../libs/style';

import styles from './Nicon.module.scss';

export default function Nicon(props) {
  let { icon, size, color, className } = props;
  let style = new Style(styles);

  let icons = {
    ex: <>&#xE609;</>, // X close
    check: <>&#xE642;</>, // checked
    chevup: <>&#xE67C;</>, // chevron up
    chevleft: <>&#xE622;</>, // chevron left
    chevright: <>&#xE634;</>, // chevron right
    info: <>&#xE61F;</>, // information
    nifront: <>&#xE96B;</>, // nissan can front
    engine: <>&#xE96D;</>, // engine
    color: <>&#xE68C;</>, // color page menu
    upholstery: <>&#xE917;</>, // upholstery page menu
    wheel: <>&#xE67B;</>, // wheel page menu
    drivewheel: <>&#xE641;</>, // drvewheel
    option: <>&#xE919;</>, // options page menu
    accessory: <>&#xE955;</>, // accessories page menu
    checklist: <>&#xE91B;</>, // checklist board
    print: <>&#xE631;</>, // printer
    share: <>&#xE673;</>, // share
    save: <>&#xE684;</>, // floppy
    pump: <>&#xE61A;</>, // pumping station
    shift: <>&#xE624;</>, // gear shift
    suspension: <>&#xE90A;</>, // suspension
    calc: <>&#xE68F;</>, // calculator
    edit: <>&#xE612;</>, // edit pen
    reload: <>&#xE633;</>, // reload circle
    plus: <>&#xE66C;</>, // +
    minus: <>&#xE627;</>, // -
    warning: <>&#xE68D;</>, // warning sign
  };

  return (
    <div
      onClick={props.onClick}
      className={style.compose(style.css('nicon'), className)}
      style={inlineStyle(size)}>
      {typeIcon(icon)}
    </div>
  );

  function typeIcon(icon) {
    return icon ? icons[icon] : <>&#xE642;</>;
  }

  function inlineStyle(size = 11) {
    return {
      fontSize: size,
      color: color,
    };
  }
}
