import { getArrayItemIndexByKeyValue } from '../libs/helper';

let defaultData = [
  {
    route: '/equipment',
    title: 'felszereltségi szint',
    langKey: 'Equipment level',
    icon: 'nifront',
  },
  {
    route: '/engine',
    title: 'motor',
    langKey: 'Engine',
    icon: 'engine',
  },
  {
    route: '/color',
    title: 'szín',
    langKey: 'Color',
    icon: 'color',
  },
  {
    route: '/upholstery',
    title: 'kárpit',
    langKey: 'Upholstery',
    icon: 'upholstery',
  },
  {
    route: '/rims',
    title: 'keréktárcsák',
    langKey: 'Wheels',
    icon: 'wheel',
  },
  {
    route: '/options',
    title: 'Csomagok és opciók',
    langKey: 'Packages and options',
    icon: 'option',
  },
  {
    route: '/accessories',
    title: 'Tartozékok',
    langKey: 'Accessories',
    icon: 'accessory',
  },
  {
    route: '/summary',
    title: 'Összegzés',
    langKey: 'Summary',
    icon: 'engine',
    hidden: true,
  },
];

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case 'SET_ACTIVE_BY_ROUTE':
      return setActiveByRoute(state, action.payload);
    case 'SET_ACTIVE_BY_INDEX':
      return setActiveByIndex(state, action.payload);
    case 'SET_MENU_SELECTION':
      return setSelectionByRoute(state, action.payload.selection, action.payload.route);
    case 'CLEAR_MENU_SELECTION':
      return clearSelection(state);
    case 'SYNC_USER_TO_MENU':
      return syncUserToMenu(state, action.payload);
    default:
      return [...state];
  }
}

function clearSelection(state) {
  let newState = [...state];

  newState.forEach((item, i) => {
    newState[i].selection = '';
  });

  return newState;
}

function syncUserToMenu(state, user) {
  let newState = [...state];
  let userData = [
    {
      route: '/equipment',
      data: user.category.name,
    },
    {
      route: '/engine',
      data: user.level.motor,
    },
    {
      route: '/color',
      data: Object.values(user.parameters.color)[0] && Object.values(user.parameters.color)[0].name,
    },
    {
      route: '/rims',
      data: Object.values(user.parameters.wheel)[0] && Object.values(user.parameters.wheel)[0].name,
    },
    {
      route: '/upholstery',
      data:
        Object.values(user.parameters.carpet)[0] && Object.values(user.parameters.carpet)[0].name,
    },
  ];

  userData.forEach((item, i) => {
    newState[getArrayItemIndexByKeyValue(newState, 'route', item.route)].selection = item.data;
  });

  return newState;
}

function setActiveByRoute(state, route) {
  return state.map((data) => {
    return data.route === route ? { ...data, active: true } : { ...data, active: false };
  });
}

function setActiveByIndex(state, index) {
  return state.map((data, i) => {
    return i === index ? { ...data, active: true } : { ...data, active: false };
  });
}

function setSelectionByRoute(state, selection, route) {
  let index = null;
  let newState = [...state];

  state.forEach((item, i) => {
    if (item.route === route) index = i;
  });

  if (index !== null) newState[index].selection = selection;

  return newState;
}
