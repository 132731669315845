import React, { useState, useRef } from 'react';
import Style from '../../libs/style';
import Nicon from '../Nicon/Nicon';

import styles from './ListDrawer.module.scss';

export default function ListDrawer(props) {
  let { size, detail } = props;
  let style = new Style(styles);
  let listRef = useRef();
  let [open, setOpen] = useState(false);

  return (
    <div
      className={style.css(
        'list-drawer',
        size && `list-drawer--${size}`,
        `list-drawer--${open ? 'open' : 'closed'}`
      )}>
      <div className={style.css('head')} onClick={handleHeadClick}>
        {detail.title}
        <Nicon icon={open ? 'minus' : 'plus'} size={20} />
      </div>
      <div className={style.css('list')} ref={listRef}>
        <div
          className={style.css('list__wrapper')}
          dangerouslySetInnerHTML={{ __html: detail.body }}></div>
      </div>
    </div>
  );

  function handleHeadClick() {
    setOpen(!open);
  }
}
