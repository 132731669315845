export function fetchEquipments(carId) {
  return (dispatch, getState) => {
    fetch(`${window.App.config.apiURL}/${getState().domain.current}/category?car=${carId}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setEquipmentData(data.data));
      });
  };
}

export function setEquipmentData(data) {
  return {
    type: 'SET_EQUIPMENT_DATA',
    payload: data,
  };
}

export function setEquipmentSelection(id) {
  return {
    type: 'SET_EQUIPMENT_SELECTION',
    payload: id,
  };
}
