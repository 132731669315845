import { combineReducers } from 'redux';

import domain from './domain';
import ui from './ui';
import menu from './menu';
import modell from './modell';
import equipment from './equipment';
import engine from './engine';
import user from './user';
import color from './color';
import upholstery from './upholstery';
import wheel from './wheel';
import option from './option';
import accessory from './accessory';
import summary from './summary';

let appReducer = combineReducers({
  domain,
  ui,
  menu,
  modell,
  equipment,
  engine,
  user,
  color,
  upholstery,
  wheel,
  option,
  accessory,
  summary,
});

const rootReducer = (state, action) => {
  if (action.type === 'EXIT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
